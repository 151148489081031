import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Select, snackbar } from "@mobiscroll/react5";
import axios from "axios";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useQuery } from "react-query";
import upload from "src/assets/upload.svg";
import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import Loader from "src/components/Dashboard/common/Loader";
import {
  UPLOAD_FILE_FOR_CLINIC,
  UPLOAD_FILE_FOR_DOCG,
} from "src/components/graphql/GraphQLmutation";
import {
  GET_FILE_FOR_CLINIC,
  GET_FILE_FOR_DOCG,
} from "src/components/graphql/GraphQLquery";
import getFilenameFromS3Link from "src/lib/getFilenameFromS3Link";

export async function uploadFileForDocg(data) {
  try {
    const response = await postMutation(UPLOAD_FILE_FOR_DOCG, data, true);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.uploadFileForDocg?.statusCode < 300 &&
        response?.data?.uploadFileForDocg?.statusCode >= 200
      )
    ) {
      throw new Error("error", response?.data?.uploadFileForDocg?.message);
    } else {
      return response?.data?.uploadFileForDocg?.data ?? true;
    }
  } catch (e) {
    snackbar({
      message: e.message ?? "Some error occured while uploading file",
      color: "danger",
    });
    console.log("Error at uploading file: ", e);
    return false;
  }
}

export async function uploadFileForClinic(fileType) {
  try {
    const response = await postMutation(
      UPLOAD_FILE_FOR_CLINIC,
      { fileType },
      true
    );

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.uploadGenericTermsFileForClinic?.statusCode < 300 &&
        response?.data?.uploadGenericTermsFileForClinic?.statusCode >= 200
      )
    ) {
      throw new Error(
        "error",
        response?.data?.uploadGenericTermsFileForClinic?.message
      );
    } else {
      return response?.data?.uploadGenericTermsFileForClinic?.data ?? true;
    }
  } catch (e) {
    snackbar({
      message: e.message ?? "Some error occured while uploading file",
      color: "danger",
    });
    console.log("Error at uploading file: ", e);
    return false;
  }
}

export async function getFileForDocg(fileType) {
  console.log(fileType);
  try {
    const response = await getQuery(GET_FILE_FOR_DOCG, { fileType }, true);
    console.log(response);
    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.getFileForDocg?.statusCode < 300 &&
        response?.data?.getFileForDocg?.statusCode >= 200
      )
    ) {
      throw new Error(
        response?.data?.getFileForDocg?.message || "Some Error occured"
      );
    } else {
      snackbar({
        message: "File Fetched Successfully",
        color: "success",
      });
      return response?.data?.getFileForDocg?.data;
    }
  } catch (e) {
    snackbar({
      message: e?.message,
      color: "danger",
    });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}
export async function getFileForClinic(fileType) {
  console.log(fileType);
  try {
    const response = await getQuery(GET_FILE_FOR_CLINIC, { fileType }, true);
    console.log(response);
    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.getGenericTermsFileForClinic?.statusCode < 300 &&
        response?.data?.getGenericTermsFileForClinic?.statusCode >= 200
      )
    ) {
      throw new Error(
        response?.data?.getGenericTermsFileForClinic?.message ||
          "Some Error occured"
      );
    } else {
      snackbar({
        message: "File Fetched Successfully",
        color: "success",
      });
      return response?.data?.getGenericTermsFileForClinic?.data;
    }
  } catch (e) {
    snackbar({
      message: e?.message,
      color: "danger",
    });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}

function App() {
  //   const closeAll = () => {
  //     mbsc_ref?.current?.instance.hide();
  //     upgrade_ref?.current?.instance.hide();
  //     usage_ref?.current?.instance.hide();
  //     payment_ref?.current?.instance.hide();
  //   };

  //   const toggleLast = () => {
  //     payment_ref?.current?.instance.toggle();
  //   };

  const [fileType, setFileType] = useState("TermsAndConditions");
  const [loader, setLoader] = useState(false);

  const uploadFile = async (file, fileType, setFile) => {
    if (file) {
      const ext = file.name.split(".").pop();
      const res = await uploadFileForDocg({ fileType, ext: ext });
      await axios.put(res.url, file, {
        headers: {
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Origin": "*",
        },
      });
      setFile(null);
      // setFile(file);
      return true;
    } else {
      // setContractFile(undef)
      return false;
    }
  };

  const uploadFileClinic = async (file, fileType) => {
    if (file) {
      const res = await uploadFileForClinic(fileType);
      await axios.put(res.url, file, {
        headers: {
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // setFile(file);
      return true;
    } else {
      // setContractFile(undef)
      return false;
    }
  };

  return (
    <div title="Terms and Conditions" className="py-2 w-100">
      {/* <mobiscroll.Note className="mbsc-align-center" color="primary">
        Use the accordion on forms to open only one panel at a time.
      </mobiscroll.Note> */}
      <Loader show={loader} />
      <Card className="docg-card">
        <Card.Header className="docg-card-header">
          <Card.Title className="docg-card-title d-flex align-items-center ">
            Templates
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="mbsc-row">
            <div className="mbsc-col-6">
              <TncCard
                uploadFile={uploadFile}
                list={[
                  {
                    text: "Terms and Conditions",
                    value: "TermsAndConditionsForApp",
                  },
                  // { text: "Privacy Policy", value: "PrivacyPolicy" },
                ]}
                title="App/Patient from DocG"
              />
            </div>
            <div className="mbsc-col-6">
              <TncCard
                uploadFile={uploadFile}
                list={[
                  {
                    text: "Terms and Conditions",
                    value: "TermsAndConditionsForClinic",
                  },
                  { text: "Contract", value: "ClinicContract" },
                  // { text: "Privacy Policy", value: "ClinicPrivacyPolicy" },
                  // { text: "Terms of use", value: "ClinicTermsOfUse" },
                ]}
                title="Clinics from DocG"
              />
            </div>
          </div>
          <div className="mbsc-row mt-4">
            <div className="mbsc-col-6">
              <TncCard
                uploadFile={uploadFileClinic}
                list={[
                  // { text: "Patient Disclaimer", value: "PatientDisclaimer" },
                  {
                    text: "Clinic Terms And Conditions for App",
                    value: "ClinicTermsAndConditionsForApp",
                  },
                ]}
                title="From Clinics - App"
                type="clinic"
              />
            </div>
          </div>

          <div className="mbsc-col-6 mt-4"></div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default App;

const TncCard = ({ title, list, uploadFile, type }) => {
  const [uploaded_file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);

  const {
    data: file_link,
    refetch,
    loading,
  } = useQuery(
    ["docg files", fileType, type],
    async function ({ queryKey }) {
      try {
        let res;
        if (type == "clinic") {
          res = await getFileForClinic(queryKey[1]);
        } else {
          res = await getFileForDocg(queryKey[1]);
        }
        return res?.url;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    {
      enabled: !!fileType,
    }
  );

  return (
    <Card className="docg-card">
      <Loader show={loading} />
      <Card.Header className="docg-card-header">
        <Card.Title className="docg-card-title d-flex align-items-center ">
          {title}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="mbsc-row">
          <div className="mbsc-col-12">
            <Select
              value={fileType}
              data={list}
              placeholder="Please select"
              onChange={(e) => {
                setFileType(e.value);
                setFile(null);
              }}
            />
          </div>
          {
            fileType ? (
              <>
                <div className="mbsc-col-12">
                  <div className="docg-signup-upload col-12 col-md-6 col-lg-12 mb-2">
                    <div className="upload-btn  purple-button">
                      <img src={upload} alt="" />
                      Upload File
                    </div>
                    <input
                      id="check-upload"
                      type="file"
                      accept={
                        fileType == "ClinicContract"
                          ? ".html"
                          : "application/pdf"
                      }
                      style={{ display: "block" }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      file={uploaded_file}
                    />
                  </div>
                </div>
                {file_link && (
                  <>
                    <div className="mbsc-col-12 text-center align-items-center justify-content-center gap-4">
                      <a
                        href={file_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getFilenameFromS3Link(file_link)}
                      </a>
                    </div>
                  </>
                )}
              </>
            ) : null
            // <div className="mbsc-error-message mbsc-ios">
            //   Please select file type
            // </div>
          }
          {uploaded_file ? (
            <>
              <div className="d-flex w-100 align-items-center justify-content-center">
                <button
                  onClick={async () => {
                    await uploadFile(uploaded_file, fileType, setFile);
                    refetch();
                  }}
                  className="docg-save-btn"
                >
                  Upload
                </button>
              </div>
            </>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};
