import React, { Fragment, lazy, Suspense, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";
import { Card, CardContent, Page, TabNav, NavItem } from "@mobiscroll/react";
import { useStore } from "./store/Store";
import {
  isActiveTab,
  setAppointmentId,
  isStartingConsultation,
  setPatientId,
  setUnsaved,
  setConsultationType,
  handleConsultationPopupChange,
} from "./store/PatientDetailsReducer";
import Prescription from "src/assets/icons/Bottle.svg";
import Vaccination from "src/assets/icons/Vaccination.svg";
import LabTest from "src/assets/icons/LabTest.svg";
import "./patient.scss";
import mobiscroll from "@mobiscroll/react";

import TabLeave from "./Tabs/Modals/TabLeave";
import Diagnosis from "./Tabs/diagnosis/DiagnosisTab";
import DiagnosisTab from "./Tabs/diagnosis/DiagnosisTab";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const PrescriptionTab = lazy(() =>
  import("./Tabs/prescription/PrescriptionTab")
);
const LabTestTab = lazy(() => import("./Tabs/labTest/LabTestTab"));
const VaccinationTab = lazy(() => import("./Tabs/vaccination/VaccinationTab"));
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PatientTabs = (props) => {
  const { globalState, dispatch } = useStore();
  const { patientId } = useParams();
  const tabRef = React.useRef();

  const [loader, setLoader] = useState(false);
  const [tabLeave, setTabLeave] = useState(false);
  const [data, setData] = React.useState(null);
  const [clicked, setClicked] = React.useState(null);
  const location = useLocation();
  const isGlobalTemplate = location.pathname.split("/").includes("global-template");

  React.useEffect(() => {
    dispatch(setPatientId(patientId));
  }, []);

  React.useEffect(() => {
    setData(globalState);
  }, [globalState]);

  function onItemTap(event, inst) {
    setClicked(event);
    if (globalState.activeTab != event.target.dataset.id) {
      if (globalState.unsaved) {
        tabRef.current.instance.deselect(event.target);
        tabRef.current.instance.select(globalState.activeTab);
        setTabLeave(true);
      } else {
        tabRef.current.instance.select(event.target);
        dispatch(isActiveTab(event.target.dataset.id));
      }
    }
  }

  const handleYesTabLeave = () => {
    tabRef.current.instance.select(clicked.target);
    dispatch(isActiveTab(clicked.target.dataset.id));
    dispatch(setUnsaved(false));
    setTabLeave(false);
  };
  const handleNoTabLeave = () => {
    setTabLeave(false);
  };

  return (
    <Fragment>
      <Loader show={loader} />
      <TabLeave
        show={tabLeave}
        closePopup={() => setTabLeave(!tabLeave)}
        handleYes={handleYesTabLeave}
        handleNo={handleNoTabLeave}
      />
      <Card className="docg-card docg-patient-card m-0 mb-1">
        <CardContent className="docg-card-content docg-patient-bar">
          <TabNav ref={tabRef} className="docg-tabs">
            <NavItem
              id="diagnosis"
              selected={globalState.activeTab === "diagnosis"}
              onClick={onItemTap}
            >
              <img src={Prescription} alt="" />
              Diagnosis
            </NavItem>
            <NavItem
              id="prescription"
              selected={globalState.activeTab === "prescription"}
              onClick={onItemTap}
            >
              <img src={Prescription} alt="" />
              Prescription
            </NavItem>
            <NavItem
              onClick={onItemTap}
              id="labTest"
              selected={globalState.activeTab === "labTest"}
            >
              <img src={LabTest} alt="" />
              Lab Test
            </NavItem>
            {!(!!patientId && isGlobalTemplate) && <NavItem
              onClick={onItemTap}
              id="vaccination"
              selected={globalState.activeTab === "vaccination"}
            >
              <img src={Vaccination} alt="" />
              Vaccination
            </NavItem>}
          </TabNav>
          <div className="docg-patient-body">
            {globalState.activeTab === "diagnosis" && (
              <Suspense fallback={<div>Loading....</div>}>
                <DiagnosisTab showPopup={true} {...props} />
              </Suspense>
            )}
            {globalState.activeTab === "prescription" && (
              <Suspense fallback={<div>Loading....</div>}>
                <PrescriptionTab showPopup={true} {...props} />
              </Suspense>
            )}
            {globalState.activeTab === "labTest" && (
              <Suspense fallback={<div>Loading....</div>}>
                <LabTestTab showPopup={true} {...props} />
              </Suspense>
            )}
            {globalState.activeTab === "vaccination" && patientId!=undefined &&(
              <Suspense fallback={<div>Loading....</div>}>
                <VaccinationTab {...props} />
              </Suspense>
            )}
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default PatientTabs;
