import clsx from "clsx";
import { Fragment } from "react";
import { Card } from "react-bootstrap";

const DocgCard = ({
  title = "",
  className = "",
  leading,
  trailing,
  children,
}) => {
  return (
    <Fragment>
      <Card className="docg-card">
        <Card.Header className="customCardHeader d-flex justify-content-between">
          {leading}
          <Card.Title className="customCardTitle">{title}</Card.Title>
          {trailing}
        </Card.Header>
        <Card.Body className={clsx("docg-card-body p-0", className)}>
          {children}
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default DocgCard;
