import { AgGridColumn, AgGridReact } from "ag-grid-react";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";
import Card from "src/reusable/Card";
import {
  cancelRazorpaySubscription,
  fetchSubscriptionById,
  fetchSubscriptionPlanById,
  getClinicProfile,
} from "./api";

const SubscriptionHistory = () => {
  const { id } = useParams();
  const [loader, setLoader] = React.useState(false);
  const { data, refetch: refetchIds } = useQuery(
    ["list all subscription ids", id],
    async function ({ queryKey }) {
      const res = await getClinicProfile(queryKey[1]);
      return {
        previousSubscriptions: res.previousSubscriptions,
        currentSubscriptionId: res.currentSubscriptionId,
      };
    }
  );

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 150,
    suppressMovable: true,
  };

  const { data: list, refetch: refetchList } = useQuery(
    ["list all subscription details", data],
    async function ({ queryKey }) {
      const res = await Promise.all(
        [
          queryKey[1].currentSubscriptionId,
          ...(queryKey[1].previousSubscriptions ?? []),
        ]
          .filter((id) => !!id)
          .map(async function (id) {
            const _res = await fetchSubscriptionById(id);
            const planDetails = await fetchSubscriptionPlanById(_res.planId);
            return { ..._res, planDetails };
          })
      );
      console.log(res);
      return res;
    },
    {
      enabled: !!data && Object.keys(data).length > 0,
    }
  );

  const handleCancel = async () => {
    setLoader(true);

    const res = await cancelRazorpaySubscription(id);
    if (res) {
      await refetchIds();
      await refetchList();
    }
    setLoader(false);
  };

  const SubscriptionlinkGenerator = (params) => {
    return (
      <a target="_blank" href={params.data.shortUrl} rel="noreferrer">
        {params.data.subscriptionId}
      </a>
    );
  };

  return (
    <div className="mbsc-col-12 mt-2 gap-2 d-flex flex-column">
      <Loader show={loader} />
      {data?.currentSubscriptionId &&
      list &&
      !["cancelled", "expired"].includes(list[0].status) ? (
        <>
          <Card
            className="d-flex flex-column gap-2 pt-4 pb-4"
            title="Current Subscription details"
          >
            <div className="mbsc-row">
              <div className="mbsc-col-3">Plan:</div>
              <div className="mbsc-col-9">
                {list[0].planDetails.planId}
                <br />
                {list[0].planDetails.item.name} <br />
                {list[0].planDetails.item.description} <br />
                &#8377; {list[0].planDetails.item.amount.toLocaleString()}{" "}
                Billed Every {list[0].planDetails.interval}{" "}
                {list[0].planDetails.period.split("ly")[0]}s <br />
              </div>
            </div>
            <div className="mbsc-row">
              <div className="mbsc-col-3">Link</div>
              <div className="mbsc-col-3">
                <a href={list[0].shortUrl} target="_blank" rel="noreferrer">
                  {list[0].shortUrl}
                </a>
              </div>
            </div>
            <div className="mbsc-row">
              <div className="mbsc-col-3">Status</div>
              <div
                style={{ textTransform: "capitalize" }}
                className="mbsc-col-9"
              >
                {" "}
                <button type="button" className="docg-outline-btn mr-2">
                  {list[0].status}{" "}
                </button>
                <button onClick={handleCancel} className="docg-outline-btn">
                  Cancel Subscription
                </button>
              </div>
            </div>

            <div className="mbsc-row">
              <div className="mbsc-col-3">Created At</div>
              <div className="mbsc-col-9">
                {" "}
                {moment
                  .unix(list[0].createdAt)
                  .local()
                  .format("DD/MM/YYYY hh:mm a")}
              </div>
            </div>
            <div className="mbsc-row">
              <div className="mbsc-col-3">Next due on</div>
              <div className="mbsc-col-9">
                {" "}
                {moment
                  .unix(list[0].chargeAt)
                  .local()
                  .format("DD/MM/YYYY hh:mm a")}
              </div>
            </div>
          </Card>
        </>
      ) : null}
      <Card title="Subscription List">
        {/*
        <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              <Select
                touchUi={false}
                onChange={onPageSizeChanged}
                value={displayRecords}
                data={recordList}
              />
            </label>
            <span>&nbsp;Records</span>
          </div>
          */}
        <div
          className="ag-theme-alpine"
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <AgGridReact
            rowData={list ?? []}
            defaultColDef={defaultColDef}
            frameworkComponents={{
              subscriptionlinkGenerator: SubscriptionlinkGenerator,
            }}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize={10}
            domLayout={"autoHeight"}
            animateRows={true}
            suppressAggFuncInHeader={true}
            suppressColumnVirtualisation={true}
          >
            <AgGridColumn
              headerName="Subscription ID"
              field="subscriptionId"
              tooltipField="subscriptionId"
              cellRenderer="subscriptionlinkGenerator"
              minWidth={250}
            ></AgGridColumn>
            <AgGridColumn headerName="Plan ID" field="planId"></AgGridColumn>
            {/* <AgGridColumn
              headerName="Subscription Link"
              field="shortUrl"
            ></AgGridColumn> */}
            <AgGridColumn
              headerName="Amount"
              field="planDetails.item.amount"
              valueFormatter={(params) => params?.value?.toLocaleString()}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Interval"
              field="planDetails.interval"
            ></AgGridColumn>
            <AgGridColumn
              headerName="Period"
              field="planDetails.period"
            ></AgGridColumn>
            <AgGridColumn
              headerName="Subscription type"
              field="planDetails.subscriptionType"
              valueFormatter={(params) => params.value ?? "-"}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Created At"
              field="createdAt"
              valueFormatter={(params) => {
                if (params.value) {
                  let d = new Date(0);
                  d.setUTCSeconds(params.value);
                  return d.toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hours: "2-digit",
                    minutes: "2-digit",
                  });
                }
                return "-";
              }}
            ></AgGridColumn>
            <AgGridColumn
              headerName="Payment Cycles done"
              field="totalCount"
              valueFormatter={(params) =>
                `${params.data.paidCount}/${params.data.totalCount}`
              }
            ></AgGridColumn>
            <AgGridColumn
              cellClass={{ textTransform: "capitalize" }}
              field="status"
            ></AgGridColumn>
          </AgGridReact>
        </div>
      </Card>
    </div>
  );
};

export default SubscriptionHistory;
