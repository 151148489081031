import { gql } from "@apollo/client";

export const CLINIC_CREATION = gql`
  mutation MyMutation($data: AddClinicCreationRequest!) {
    addClinicCreationRequest(data: $data) {
      statusCode
    }
  }
`;

export const ADD_CLINIC_LEAD = gql`
  mutation MyMutation($data: AddClinicLeadRequest!) {
    addClinicLead(data: $data) {
      message
      statusCode
    }
  }
`;

export const CLINIC_APPROVAL = gql`
  mutation MyMutation($data: ApproveClinicCreationRequestInput!) {
    approveClinicCreationRequest(data: $data) {
      message
      statusCode
    }
  }
`;

export const UPLOAD_CLINIC_IMAGE = gql`
  mutation MyMutation($data: UpdateClinicRequest!) {
    updateClinic(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_LAB_TEST_TEMPLATE = gql`
  mutation MyMutation($data: DiagnosisLabTestTemplateInput!) {
    addDiagnosisLabTestTemplate(data: $data) {
      statusCode
      message
    }
  }
`;
export const ADD_PRESCRIPTION_TEMPLATE = gql`
  mutation MyMutation($data: AddDiagnosisPrescriptionTemplateRequest!) {
    addDiagnosisPrescriptionTemplate(data: $data) {
      data {
        prescriptionTemplateName
        drugs {
          additionalAdvice
          composition
          dose
          drugName
          drugType
          duration
          gapBetweenDosage
          gapBetweenDosageType
          id
          instruction
          period
        }
      }
      message
      statusCode
    }
  }
`;

export const ADD_VACCINATION_TEMPLATE = gql`
  mutation MyMutation($data: VaccinationTemplateRequest!) {
    addVaccinationTemplate(data: $data) {
      message
      statusCode
    }
  }
`;

export const CLINIC_REJECTION = gql`
  mutation MyMutation($data: RejectClinicCreationRequestInput!) {
    rejectClinicCreationRequest(data: $data) {
      message
      statusCode
    }
  }
`;

export const ADD_ACCOUNT_MANAGER = gql`
  mutation MyMutation($data: addAccountManagerInput!) {
    addAccountManager(data: $data) {
      message
      statusCode
    }
  }
`;

export const ACTIVATE_CLINIC = gql`
  mutation MyMutation($data: DeactivateClinic!) {
    activateClinic(data: $data) {
      message
      statusCode
    }
  }
`;
export const DEACTIVATE_CLINIC = gql`
  mutation MyMutation($data: DeactivateClinic!) {
    deactivateClinic(data: $data) {
      message
      statusCode
    }
  }
`;
export const CREATE_RAZORPAY_PLAN = gql`
  mutation MyMutation($data: CreateRazorpayPlanInput!) {
    createRazorpayPlan(data: $data) {
      message
      statusCode
    }
  }
`;
export const DEACTIVATE_RAZORPAY_PLAN = gql`
  mutation MyMutation($subscriptionType: String!) {
    deactivateAllRazorpayPlan(subscriptionType: $subscriptionType) {
      message
      statusCode
    }
  }
`;
export const CANCEL_RAZORPAY_SUBSCRIPTION = gql`
  mutation MyMutation($data: String!) {
    cancelRazorpaySubscription(clinicId: $data) {
      message
      statusCode
    }
  }
`;

export const UPLOAD_FILE_FOR_DOCG = gql`
  mutation MyMutation($fileType: GenericUploadFileType!, $ext: String!) {
    uploadFileForDocg(ext: $ext, fileType: $fileType) {
      data {
        url
        termsFor
        previousFileNames
        name
        fileType
      }
      message
      statusCode
    }
  }
`;

export const UPLOAD_FILE_FOR_CLINIC = gql`
  mutation MyMutation($fileType: GenericUploadFileType!) {
    uploadGenericTermsFileForClinic(fileType: $fileType) {
      data {
        url
        termsFor
        previousFileNames
        name
        fileType
      }
      message
      statusCode
    }
  }
`;

export const REMOVE_CLINIC = gql`
  mutation MyMutation($clinicId: String!, $deletionStatus: DeletionStatus!) {
    removeClinic(clinicId: $clinicId, deletionStatus: $deletionStatus) {
      message
      statusCode
    }
  }
`;

export const ADD_DIAGNOSIS_TEMPLATE = gql`
  mutation MyMutation($data: DiagnosisTableTemplateInput!) {
    addDiagnosisTemplate(data: $data) {
      message
      statusCode
    }
  }
`;
