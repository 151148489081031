import { snackbar } from "@mobiscroll/react5";
import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  CANCEL_RAZORPAY_SUBSCRIPTION,
  CREATE_RAZORPAY_PLAN,
  DEACTIVATE_RAZORPAY_PLAN,
} from "src/components/graphql/GraphQLmutation";
import {
  FETCH_SUBSCRIPTION_BY_ID,
  FETCH_SUBSCRIPTION_PLAN_BY_ID,
  GET_CLINIC_PROFILE,
  LIST_ACTIVE_RAZORPAY_PLANS,
  LIST_ALL_RAZORPAY_PLANS,
} from "src/components/graphql/GraphQLquery";

export async function cancelRazorpaySubscription(id) {
  try {
    const response = await postMutation(CANCEL_RAZORPAY_SUBSCRIPTION, id);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.cancelRazorpaySubscription?.statusCode < 300 &&
        response?.data?.cancelRazorpaySubscription?.statusCode >= 200
      )
    ) {
      throw new Error("error", response);
    } else {
      return true;
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Cancelling subscription. Try again",
      color: "danger",
    });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}
export async function createRazorpayPlan(data) {
  try {
    const response = await postMutation(CREATE_RAZORPAY_PLAN, data);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.createRazorpayPlan?.statusCode < 300 &&
        response?.data?.createRazorpayPlan?.statusCode >= 200
      )
    ) {
      throw new Error(response?.data?.createRazorpayPlan?.message);
    } else {
      snackbar({
        message: "Plan Added successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    console.log(e?.message);
    snackbar({
      message: e?.message || "Some error occured while Adding Plan. Try again",
      color: "danger",
    });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}
export async function deactivateAllRazorpayPlan(subscriptionType) {
  try {
    const response = await postMutation(
      DEACTIVATE_RAZORPAY_PLAN,
      { subscriptionType },
      true
    );

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.deactivateAllRazorpayPlan?.statusCode < 300 &&
        response?.data?.deactivateAllRazorpayPlan?.statusCode >= 200
      )
    ) {
      throw new Error(response?.data?.deactivateAllRazorpayPlan?.message);
    } else {
      snackbar({
        message: subscriptionType + " Plans Deactivated successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    console.log(e?.message);
    snackbar({
      message: e?.message || "Some error occured while Adding Plan. Try again",
      color: "danger",
    });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}

export async function listAllRazorpayPlans() {
  try {
    const response = await getQuery(LIST_ALL_RAZORPAY_PLANS);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.listAllRazorpayPlans?.statusCode < 300 &&
        response?.data?.listAllRazorpayPlans?.statusCode >= 200
      )
    ) {
      throw new Error("error", response);
    } else {
      // snackbar({
      //   message: "Account Added successfully.",
      //   color: "success",
      // });
      return response?.data?.listAllRazorpayPlans?.data;
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Adding Account. Try again",
      color: "danger",
    });
    console.log("Error at listAllRazorpayPlans: ", e.msg);
    return [];
  }
}

export async function listActiveRazorpayPlans() {
  try {
    const response = await getQuery(LIST_ACTIVE_RAZORPAY_PLANS);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.listAllRazorpayPlans?.statusCode < 300 &&
        response?.data?.listAllRazorpayPlans?.statusCode >= 200
      )
    ) {
      throw new Error("error", response);
    } else {
      // snackbar({
      //   message: "Account Added successfully.",
      //   color: "success",
      // });
      return response?.data?.listAllRazorpayPlans?.data;
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Adding Account. Try again",
      color: "danger",
    });
    console.log("Error at listAllRazorpayPlans: ", e.msg);
    return [];
  }
}
export async function getClinicProfile(data) {
  try {
    const response = await getQuery(GET_CLINIC_PROFILE, { id: data });
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.getClinicProfile?.statusCode >= 200 &&
        response?.data?.getClinicProfile?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      if (response?.data?.getClinicProfile?.data) {
        return response?.data?.getClinicProfile?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return [];
  }
}
export async function fetchSubscriptionById(id) {
  try {
    const response = await getQuery(FETCH_SUBSCRIPTION_BY_ID, id);
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.fetchSubscriptionById?.statusCode >= 200 &&
        response?.data?.fetchSubscriptionById?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      if (response?.data?.fetchSubscriptionById?.data) {
        return response?.data?.fetchSubscriptionById?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return [];
  }
}
export async function fetchSubscriptionPlanById(id) {
  try {
    const response = await getQuery(FETCH_SUBSCRIPTION_PLAN_BY_ID, id);
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.fetchSubscriptionPlanById?.statusCode >= 200 &&
        response?.data?.fetchSubscriptionPlanById?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      if (response?.data?.fetchSubscriptionPlanById?.data) {
        return response?.data?.fetchSubscriptionPlanById?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return {};
  }
}
