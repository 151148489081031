import "animate.css";
import { createBrowserHistory } from "history";
import React, { Suspense, useState } from "react";
import "react-notifications-component/dist/theme.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "react-router-dom";
import "./App.scss";
import "./global.scss";
import Routers from "./navigators/Routers";
import { StoreProvider } from "./store/Store";
import { Modal } from "react-bootstrap";
import { Amplify } from "aws-amplify";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

console.log(
  process.env.REACT_APP_CLINIC_URL,
  process.env.REACT_APP_IDENTITY_POOL_ID,
  process.env.REACT_APP_REGION
);

Amplify.configure({
  API: {
    GRAPHQL: {
      endpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
      region: process.env.REACT_APP_REGION,
      defaultAuthMode: "userPool",
    },
  },
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      userPoolId: process.env.REACT_APP_USERPOOL_ID,
      userPoolClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT,
      loginWith: {
        email: true,
        username: false,
      },
      mfa: {
        totpEnabled: false,
        smsEnabled: false,
      },
    },
  },
});

const App = () => {
  const [isOpen, setOpen] = useState(false);
  React.useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight - 1}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);

  const history = createBrowserHistory();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <Suspense fallback={"Loading..."}>
            <Router history={history}>
              <Routers />
              <Modal
                size="md"
                show={isOpen}
                onHide={() => setOpen(false)}
                dialogClassName="docg-modal"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="p-4">
                  Please use landscape mode for better user experience
                </Modal.Body>
              </Modal>
              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{ style: { opacity: 0.2 } }}
              />
            </Router>
          </Suspense>
        </StoreProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
