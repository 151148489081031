export const UPDATE_USER = "UPDATE_USER";

export const userInitialState = {
  userId: null,
  userMobile: null,
  userEmail: null,
  loginStatus: null,
  userRoles: null,
};

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});

export const userReducer = (state, action, initialState) => {
  console.log(state, action, initialState);
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, ...action.payload };
    default:
      break;
  }
};
