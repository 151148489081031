import React from "react";
import { useHistory } from "react-router-dom";

const ActionCellRender = (params) => {
  const LINK = `clinic-details/${params.data.id}`;
  const TEMP_LINK = `global-template/${params.data.id}`;
  const history = useHistory();

  return (
    <>
      <button
        onClick={() => {
          history.push(TEMP_LINK);
        }}
        style={{ width: "fit-content", fontSize: "12px", lineHeight: "12px" }}
        className="docg-save-btn mr-2"
      >
        Global Templates
      </button>
      <button
        onClick={() => {
          history.push(LINK);
        }}
        style={{ width: "fit-content", fontSize: "12px", lineHeight: "12px" }}
        className="docg-save-btn mr-2"
      >
        Templates
      </button>
      <button
        onClick={() => {
          history.push(`/subscription/${params.data.id}`);
        }}
        style={{ width: "fit-content", fontSize: "12px", lineHeight: "12px" }}
        className="docg-save-btn mr-2"
      >
        Subscription
      </button>
    </>
  );
};

export default ActionCellRender;
