import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";

//store
import { useStore } from "src/store/Store";
import { updateUser } from "src/store/userReducer";

import mobiscroll, { snackbar, toast } from "@mobiscroll/react";
import line from "src/assets/Lines.svg";
import grid from "src/assets/grid.svg";
import login_icon from "src/assets/login icon  human.svg";
import doctor from "src/assets/login-doctor.svg";
import Loader from "src/components/Dashboard/common/Loader";
import "../Dashboard/Layouts/Card.css";
import "./Auth.scss";
import ChangeTempPassword from "./ChangeTempPassword";

import { useHistory } from "react-router-dom";
import signin_circle from "src/assets/signin-circle.svg";
import { getQuery } from "../../components/ApolloConnection/ApolloProviderComponent";
import { getQuery as getQueryUnauthorized } from "../../components/ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import "../ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import "../ApolloConnection/UnauthorizedConnection/Credentials";
import { GET_FILE_FOR_DOCG, USER_DATA } from "../graphql/GraphQLquery";
import { signIn, signOut } from "@aws-amplify/auth";
import {
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
} from "@aws-amplify/auth";
import SetMFAasTOTP from "./SetMFAasTOTP";
import OtpVerification from "./OtpVerification";
const env = process.env.REACT_APP_ENVIRONMENT;

function SignIn() {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [link, setLink] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [authenticating, setAuthenticating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [loader, setLoader] = useState(false);
  const [changeTempPassword, setChangeTempPassword] = useState(null);
  const [congnitoUser, setCognitoUser] = useState(null);
  const [authType, setAuthType] = useState(null);
  const { globalStateUser, dispatchUser } = useStore();
  const [userData, setUserData] = useState(null);
  const [mfa, setMfa] = useState(null);

  const checkMFA = async () => {
    const user = await getCurrentUser();
    setUserData(user);
    const mfa = await fetchMFAPreference();
    return mfa;
  };

  const minMaxLength = (text, minLength, maxLength) => {
    let result = !text || text.length < minLength;
    if (maxLength) result = result || text.length < minLength;
    return result;
  };

  const validEmail = (text) => {
    const regex = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    return !regex.test(text);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = {};

    switch (name) {
      case "username":
        setUser({ ...user, username: value });
        if (!value || validEmail(value)) {
          currentFormErrors[name] = `Email address is invalid`;
        } else {
          delete currentFormErrors[name];

          localStorage.setItem("username", value);
        }
        break;
      case "password":
        setUser({ ...user, password: value });
        if (minMaxLength(value, 8)) {
          currentFormErrors[name] = `Password should have minimum 8 characters`;
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        break;
    }
    setFormErrors(currentFormErrors);
  };

  const forgotPassword = () => {
    history.push("/forgot-password");
  };

  const login = () => {
    if (user.username == null || user.password == null) {
      if (user.username == null) {
        setFormErrors({
          username: "This field is required",
        });
      }

      if (user.password == null) {
        setFormErrors({
          password: "This field is required",
        });
      }

      if ((user.username == null) & (user.password == null)) {
        setFormErrors({
          username: "This field is required",
          password: "This field is required",
        });
      }
    } else {
      toast({
        message: "Authenticating",
        color: "success",
      });
      setErrorLogin("");
      setAuthenticating(true);
      _signIn(user.username, user.password);
    }
  };

  async function onLogin() {
    setLoader(true);
    try {
        let response = await getQuery(USER_DATA);
        let roles = response.data.getUserInfo.data.roles[0];
        const { email, phone_number } = await fetchUserAttributes();
        const { userId } = await getCurrentUser();

        if (roles == "Doctor" || roles == "Admin") {
          // history.push("/clinic/dashboard");
          alert("Please Enter DOCG Admin Credentials.");
        } else {
          sessionStorage.setItem("userRole", roles);
          sessionStorage.setItem("loginStatus", true);
          sessionStorage.setItem(
            "role-id",
            response?.data?.getUserInfo?.data?.profileId
          );
          dispatchUser(
            updateUser({
              userId,
              userMobile: phone_number,
              userEmail: email,
              loginStatus: true,
              userRoles: roles,
            })
          );
      }
    } catch (error) {
      snackbar({
        message: "Some error occurred while trying to login",
        color: "danger",
      });
      console.log("Error: ", error);
    }
    setLoader(false);
  }
  React.useEffect(() => {
    if (globalStateUser.loginStatus) {
      if (globalStateUser.userRoles == "SuperAdmin") history.push("/admin");
      else history.push("/clinics");
    }
  }, [globalStateUser]);

  async function _signIn(username, password) {
    setLoader(true);
    try {
      await signOut();
      const { isSignedIn, nextStep } = await signIn({ username, password });
      console.log(isSignedIn, nextStep, username, password);
      if (nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        // history.push("/change-password");
        setChangeTempPassword(user);
      } else if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE" ||
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE" ||
        nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION"
      ) {
        if (
          nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION" ||
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
        )
          setAuthType("TOTP");
        else setAuthType("SMS");
        setCognitoUser(user);
        // history.push("/otp-verification");
      } else {
        if (env !== "dev") {
          let mfa = await checkMFA();
          if (mfa === "NOMFA") {
            setMfa(true);
          } else onLogin();
        } else onLogin();
      }
    } catch (error) {
      setAuthenticating(false);
      snackbar({
        message: "Incorrect Username or Password",
        color: "danger",
      });
    }
    setLoader(false);
  }

  if (mfa) {
    return (
      <>
        <SetMFAasTOTP userData={userData} onSuccess={onLogin} />
      </>
    );
  }

  if (congnitoUser) {
    return (
      <>
        <Loader show={loader} />
        <OtpVerification
          cognitoObject={congnitoUser}
          onSuccess={onLogin}
          mfaType={authType}
        />
      </>
    );
  }
  if (changeTempPassword) {
    return <ChangeTempPassword user={changeTempPassword} />;
  }

  const download = async () => {
    const res = await getQueryUnauthorized(GET_FILE_FOR_DOCG);
    setLink(res?.data?.getFileForDocg?.data?.url);
  };
  return (
    <Fragment>
      <Loader show={loader} />

      <Helmet>
        <title>Login | DOCG</title>
      </Helmet>
      <div className="mbsc-grid">
        {/* <button onClick={download}>Download</button>
        <a href={link}>asdfdfg</a> */}
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-6 doctor-images-left mbsc-col-12 p-0">
                <div className="left-signup-div"></div>
                <img src={doctor} className="left-signup-img" />
              </div>
              <div className="mbsc-col-xl-6 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="get-txt">Welcome Back!</p>
                    <p
                      style={{ color: "#757575" }}
                      className="signup-link mt-4"
                    >
                      To login, please enter your email and password
                    </p>
                    <p className="connect-txt"></p>
                    {/* <br /> */}
                    {/* <br /> */}
                    <br />
                    <div className="signup-wrapper-inner">
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        placeholder=""
                        name="username"
                        type="email"
                        onChange={handleChange}
                        valid={!formErrors.username}
                        errorMessage={formErrors.username}
                        disabled={authenticating}
                      >
                        Enter Email Address *
                      </mobiscroll.Input>
                      <br />
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        passwordToggle={true}
                        type="password"
                        name="password"
                        onChange={handleChange}
                        valid={!formErrors.password}
                        errorMessage={formErrors.password}
                        // disabled={authenticating}
                        className="input-style"
                        style={{ marginBottom: "10px" }}
                      >
                        Password *
                      </mobiscroll.Input>
                      <br />
                      <button
                        className="transparent-button"
                        onClick={forgotPassword}
                      >
                        Forgot Password?
                      </button>
                      <br />
                      <button
                        className="pink-button"
                        onClick={login}
                        disabled={authenticating}
                      >
                        <img src={login_icon} />
                        &nbsp;&nbsp; Login
                      </button>
                      <br />

                      <p className="text-danger text-center">{errorLogin}</p>
                    </div>
                  </div>
                </div>
                <img
                  src={line}
                  className="lines-bottom"
                  style={{ marginLeft: "8%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SignIn;
