import React, { useState } from "react";
import { Input, snackbar } from "@mobiscroll/react5";

import { postMutation } from "src/components/ApolloConnection/ApolloProviderComponent";
import { ADD_DIAGNOSIS_TEMPLATE } from "src/components/graphql/GraphQLmutation";
import Loader from "src/components/Dashboard/common/Loader";
import prescription from "src/assets/icons/prescriptionIcon.svg";
import SelectDiagnosis from "./SelectDiagnosis";
// import "../../patient.scss";
import "../prescription/prescription.scss"
import { useLocation,useParams } from "react-router-dom";

export async function addDiagnosisTemplate(data) {
  try {
    let response = await postMutation(ADD_DIAGNOSIS_TEMPLATE, data);
    if (
      response.data !== null &&
      response?.data?.addDiagnosisTemplate?.statusCode >= 200 &&
      response?.data?.addDiagnosisTemplate?.statusCode < 300
    ) {
      snackbar({
        message: response?.data?.addDiagnosisTemplate.message,
        color: "success",
      });
      return true;
    } else {
      throw new Error(response?.data?.addDiagnosisTemplate.message);
    }
  } catch (e) {
    console.log(e.message ? e.message : "Something went wrong");
    snackbar({
      message: e.message,
      color: "danger",
    });
    return false;
  }
}

const DiagnosisTab = () => {
  const [diagnosis, setDiagnosis] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const { patientId } = useParams();

  console.log(location.pathname.split("/"))
  const isGlobalTemplate = location.pathname.split("/").includes("global-template");
  console.log(isGlobalTemplate, patientId, !!patientId && isGlobalTemplate );

  const onDiagnosisChange = (e) => {
    setDiagnosis(e.target.value);
  };
  const onSpecalizationChange = (e) => {
    setSpecialization(e.target.value);
  };

  const handleSubmit = (e) => {
    if (!diagnosis || diagnosis === "") {
      snackbar({
        message: "Please enter the diagnosis name",
        color: "danger",
      });
      return;
    }
    if (!specialization || specialization === "") {
      snackbar({
        message: "Please enter the specialization name",
        color: "danger",
      });
      return;
    }
    setLoader(true);
    addDiagnosisTemplate({ diagnosisName: diagnosis, specialization });
    setLoader(false);
  };

  return (
    <div className="mbsc-grid d-flex p-0 flex-column h-100">
      <div
        style={{ flex: "1 1 auto", overflow: "hidden" }}
        className="mbsc-row"
      >
        <Loader show={loader} />
       {!(!!patientId && isGlobalTemplate) && (<div className="mbsc-col-8 docg-prescription-left pt-3 pb-3">
          <div className="row mt-2">
            <div className="mbsc-col-12 mt-2">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Diagnosis name *"
                placeholder=""
                name="diagnosis"
                type="text"
                value={diagnosis}
                onChange={onDiagnosisChange}
              />

              <span className="mbsc-error-message mbsc-ios"></span>
            </div>
            <div className="mbsc-col-12 mt-2">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Specalization name *"
                placeholder=""
                name="specialization"
                type="text"
                value={specialization}
                onChange={onSpecalizationChange}
              />
              <span className="mbsc-error-message mbsc-ios"></span>
            </div>
            <div className="mbsc-col-12 mt-2 d-flex justify-content-end">
          <button className="docg-save-btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
          </div>
        </div>)}
        <div className={`${(!!patientId && isGlobalTemplate) ? "mbsc-col-12":"mbsc-col-4"} docg-select-prescription p-0`}>
          <SelectDiagnosis
            img={prescription}
            title="Template"
            placeholder="Search Diagnosis"
          />
        </div>
        
      </div>
    </div>
  );
};

export default DiagnosisTab;
