import React, { Fragment, useCallback, useState, Suspense } from "react";
import { useStore } from "./store/Store";
import Loader from "src/components/Dashboard/common/Loader";
import { useParams } from "react-router-dom";
import { Card, CardContent, Button } from "@mobiscroll/react";
import Avatar, { ConfigProvider } from "react-avatar";

//api
import { getClinicProfile } from "./api/index";

const PatientSidebar = (props) => {
  const { globalState, dispatch } = useStore();

  const [patientDetails, setPatientDetails] = useState(null);
  const [name, setName] = useState(null);

  React.useEffect(() => {
    let name = [
      patientDetails?.title,
      patientDetails?.firstName,
      patientDetails?.lastName,
    ];

    name = name.filter((key) => key != null).join(" ");
    setName(name);
    return () => null;
  }, [patientDetails]);
  const [loader, setLoader] = useState(false);
  const { patientId } = useParams();

  React.useEffect(() => {
    async function call() {
      setLoader(true);
      const res = await getClinicProfile(patientId);
      setPatientDetails(res);
      setLoader(false);
    }
    call();
    return () => null;
  }, []);

  return (
    <Fragment>
      <Loader show={loader} />
      <Card className="docg-card docg-patient-sidebar">
        {/* <img src={PatientImage} className="docg-card-image" alt="doctor" /> */}
        <CardContent className="docg-card-content docg-patient-body px-0">
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              {/* <div className="mbsc-col-5 mbsc-col-sm-5 mbsc-col-md-5 mbsc-col-lg-5 pl-0 mt-1"> */}
              <div className="d-flex justify-content-center align-items-center ml-2">
                <Avatar
                  color="#c06c84"
                  round
                  size="50"
                  name={patientDetails?.name}
                  src={patientDetails?.logoLink}
                />
              </div>
              {/* </div> */}

              <div className="docg-patient-name ml-1">
                <span className="">{patientDetails?.name}</span>
              </div>
            </div>

            <div className="section">
              <div className="d-flex flex-column">
                <span className="docg-patient-label">Specialities</span>
                <span className="docg-patient-info">
                  {patientDetails?.specialities.length
                    ? patientDetails.specialities.join(", ")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="section">
              <div className="d-flex flex-column">
                <span className="docg-patient-label">Contact Detail</span>
                <span className="docg-patient-info">
                  {patientDetails?.address ? patientDetails.address : "-"}
                </span>
              </div>
            </div>
            <div className="section">
              <div className="d-flex flex-column">
                <span className="docg-patient-label">Phone No.</span>
                <span className="docg-patient-info">
                  {patientDetails?.contactDetails
                    ? patientDetails?.contactDetails
                    : "-"}
                </span>
              </div>
            </div>
            <div className="section">
              <div className="d-flex flex-column">
                <span className="docg-patient-label">Email</span>
                <span className="docg-patient-info">
                  {patientDetails?.mail ? patientDetails?.mail : "-"}
                </span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default PatientSidebar;
