export const UPDATE_EMERGENCY_NOTIFICATION = "UPDATE_EMERGENCY_NOTIFICATION";
export const UPDATE_ALL_NOTIFICATIONS = "UPDATE_ALL_NOTIFICATIONS";
export const PENDING_LAB_REPORT = "PENDING_LAB_REPORT";

export const notificationInitialState = {
  emergencyNotifications: [],
  notifications: [],
  pendingLabReports: [],
};

export const listEmergencyNotifications = (data) => ({
  type: UPDATE_EMERGENCY_NOTIFICATION,
  payload: data,
});
export const listPendingLabReports = (data) => ({
  type: PENDING_LAB_REPORT,
  payload: data,
});

export const updateAllNotifications = (data) => ({
  type: UPDATE_ALL_NOTIFICATIONS,
  payload: data,
});

export const notificationsReducer = (state, action, initialState) => {
  console.log(action, initialState, state);
  switch (action.type) {
    case UPDATE_EMERGENCY_NOTIFICATION:
      if (Array.isArray(action.payload)) {
        return { ...state, emergencyNotifications: action.payload };
      }
      return state;
    case UPDATE_ALL_NOTIFICATIONS:
      if (Array.isArray(action.payload)) {
        return { ...state, notifications: action.payload };
      }
      return state;
    case PENDING_LAB_REPORT:
      if (Array.isArray(action.payload)) {
        return { ...state, pendingLabReports: action.payload };
      }
      return state;
    default:
      return state;
  }
};
