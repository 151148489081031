import React from "react";
import page404 from "src/assets/404page.svg";

const PageNotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center page-not-found">
      <img src={page404} alt="Page not found" />
    </div>
  );
};

export default PageNotFound;
