import SubscriptionPlan from "src/components/Admin/Subscription/Plan";
import SubscriptionHistory from "src/components/Admin/Subscription/SubscriptionHistory";
import TnC from "src/components/Admin/TnC";
import ClinicActions from "src/components/Clinic";
import AccountManagerForm from "src/components/accountManagerForm/AccountManagerForm";
import AddClinic from "../AddClinic/AddClinic";
import Admin from "../Admin/Admin";
import Patient from "../PatientDetail/PatientDetail";
import RejectClinicCreationRequest from "../RejectClinicCreationRequest";
import ClinicList from "../Templates/ClinicList";
import { MFASettings } from "./MFASettings";

const DoctorRoutes = [
  {
    path: "/admin",
    modules: ["SuperAdmin"],
    auth: true,
    component: Admin,
  },
  {
    path: "/clinic-actions",
    modules: ["SuperAdmin"],
    auth: true,
    component: ClinicActions,
  },
  {
    path: "/rejected-clinics",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: RejectClinicCreationRequest,
  },
  {
    path: "/tnc",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: TnC,
  },
  {
    path: "/clinics",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: ClinicList,
  },
  {
    path: "/invite-clinic",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: AddClinic,
  },
  {
    path: "/clinic-details/:patientId",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: Patient,
  },
  {
    path: "/global-template",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: Patient,
  },
  {
    path: "/global-template/:patientId",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: Patient,
  },
  {
    path: "/mfa-settings",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: MFASettings,
  },
  {
    path: "/subscription-plans",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: SubscriptionPlan,
  },
  {
    path: "/subscription/:id",
    modules: ["SuperAdmin", "SubAdmin", "AccountManager"],
    auth: true,
    component: SubscriptionHistory,
  },
  {
    path: "/add-account-manager",
    modules: ["SuperAdmin", "SubAdmin"],
    auth: true,
    component: AccountManagerForm,
  },
];

export default DoctorRoutes;
