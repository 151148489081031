import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_DIAGNOSIS_TEMPLATES,
  LIST_MEDICINES,
  LIST_PRESCRIPTION_TEMPLATES,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import { ADD_PRESCRIPTION_TEMPLATE } from "src/components/graphql/GraphQLmutation";

export async function listDiagnosisTemplates(search, clinicId=undefined) {
  try {
    let response = await getQuery(LIST_DIAGNOSIS_TEMPLATES, { search, clinicId}, true);
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.listDiagnosisTemplates?.statusCode >= 200 &&
      response?.data?.listDiagnosisTemplates?.statusCode < 300
    ) {
      let data = response?.data?.listDiagnosisTemplates.data;
      let templates = [];
      data.map((template) => {
        templates.push({
          text: template.diagnosisName,
          value: template.diagnosisName,
        });
      });
      return templates;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching Notifications. Try again later.",
      color: "danger",
    });
    return [];
  }
}

export async function listGlobalDiagnosisTemplates(search, clinicId=undefined) {
  try {
    let response = await getQuery(LIST_DIAGNOSIS_TEMPLATES, { search, clinicId}, true);
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.listDiagnosisTemplates?.statusCode >= 200 &&
      response?.data?.listDiagnosisTemplates?.statusCode < 300
    ) {
      let data = response?.data?.listDiagnosisTemplates.data;
      return data;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching Notifications. Try again later.",
      color: "danger",
    });
    return [];
  }
}


export async function addDiagnosisPrescriptionTemplate(data) {
  try {
    const response = await postMutation(ADD_PRESCRIPTION_TEMPLATE, data);
    if (typeof response == "undefined") {
      throw new Error(
        "Some error occured at Adding Prescription Template. Try again"
      );
    } else {
      if (
        response?.data?.addDiagnosisPrescriptionTemplate.statusCode >= 200 &&
        response?.data?.addDiagnosisPrescriptionTemplate.statusCode < 300
      ) {
        snackbar({
          message: "Added Prescription Template successfully.",
          color: "success",
        });
      } else {
        if (response?.data?.addDiagnosisPrescriptionTemplate.message) {
          if (
            response?.data?.addDiagnosisPrescriptionTemplate.message.length >
            150
          ) {
            throw new Error(
              "Some error occured at Adding Prescription Template. Try again"
            );
          } else {
            throw new Error(
              response?.data?.addDiagnosisPrescriptionTemplate.message
            );
          }
        } else if (response?.data?.addDiagnosisPrescriptionTemplate?.data) {
          return true;
        } else {
          throw new Error(
            "Some error occured at Adding Prescription Template. Try again"
          );
        }
      }
    }
  } catch (e) {
    snackbar({
      message: e.message,
      color: "danger",
    });
    console.log("Error at Adding Prescription Template: ", e);
    return false;
  }
}

export async function listMedicines(data) {
  try {
    const response = await getQuery(LIST_MEDICINES, data, true);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listMedicine?.data) {
        return response?.data?.listMedicine?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at  list mediicne: ", e);
    return [];
  }
}
export async function listPrescriptionTemplates(
  search,
  clinicId,
  filters = ""
) {
  try {
    const response = await getQuery(
      LIST_PRESCRIPTION_TEMPLATES,
      { search, clinicId, filters },
      true
    );
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listDiagnosisPrescriptionTemplates?.data) {
        return response?.data?.listDiagnosisPrescriptionTemplates?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at  list mediicne: ", e);
    return [];
  }
}
