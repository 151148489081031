import { Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import Loader from "src/components/Dashboard/common/Loader";

const ClinicDataDownload = () => {
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(["00"]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
    minHeight: 200,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {
    minHeight: 200,
  };

  return (
    <div className="w-100">
      {" "}
      <>
        <Loader show={loader} />
        <Card className="docg-card">
          <Card.Header className="customCardHeader d-flex justify-content-between">
            <Card.Title className="customCardTitle">
              Clinics Data Download List
            </Card.Title>
            <button className="docg-save-btn">Refresh</button>
          </Card.Header>
          <Card.Body className="docg-card-body p-0">
            <div className="records-display">
              <span>Showing&nbsp;</span>
              <label className="record-selection-dropdown" htmlFor="">
                {totalRecords >= 0 && (
                  <Select
                    touchUi={false}
                    onChange={onPageSizeChanged}
                    value={displayRecords}
                    data={data}
                  />
                )}
              </label>
              <span>&nbsp;Records</span>
            </div>
            <div
              className="ag-theme-alpine"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <AgGridReact
                rowData={rowData}
                defaultColDef={defaultColDef}
                rowHeight={75}
                frameworkComponents={{}}
                defaultColGroupDef={defaultColGroupDef}
                enableRangeSelection={true}
                pagination={true}
                paginationPageSize={displayRecords}
                onGridReady={onGridReady}
                animateRows={true}
                suppressAggFuncInHeader={true}
                suppressColumnVirtualisation={true}
              >
                <AgGridColumn
                  headerName="Clinic Name"
                  field="email"
                ></AgGridColumn>

                <AgGridColumn
                  headerName="IP Address"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Requested By"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Clinic email"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Date Deletion Request date"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Date Download date"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Date Deletion date"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn headerName="Notes" field="email"></AgGridColumn>
                <AgGridColumn
                  headerName="Deletion Request"
                  field="email"
                ></AgGridColumn>
                <AgGridColumn headerName="Status" field="email"></AgGridColumn>
              </AgGridReact>
            </div>
          </Card.Body>
        </Card>
      </>
    </div>
  );
};

export default ClinicDataDownload;
