import { Checkbox, Input, snackbar } from "@mobiscroll/react5";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  fetchMFAPreference,
  getCurrentUser,
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup,
} from "@aws-amplify/auth";
import Loader from "./common/Loader";

export const MFASettings = ({ onChange = () => {}, title = true }) => {
  const [mfa, setMfa] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [codeTOTP, setCodeTOTP] = useState(null);
  const [answer, setAnswer] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [securespan, setSecurespan] = useState(true);

  useEffect(() => {
    fetchMFAPreference().then((data) => {
      setMfa(data);
    });
  }, []);

  const changePreferredMFA = (value) => {
    updateMFAPreference({
      sms: value === "SMS" ? "ENABLED" : "DISABLED",
      totp: value === "TOTP" ? "ENABLED" : "DISABLED",
    })
      .then((data) => {
        console.log(data);
        if (codeTOTP !== null) {
          setCodeTOTP(null);
        }
        if (value === "SMS") setMfa("SMS_MFA");
        else if (value === "TOTP") setMfa("SOFTWARE_TOKEN_MFA");
        else setMfa(value);
        onChange();
      })
      .catch((err) => {
        console.log(err);
        snackbar({ message: "Unable to switch MFA option", color: "danger" });
      });
  };

  const _setUpTOTP = () => {
    setLoading(true);
    setUpTOTP()
      .then(async ({ getSetupUri, sharedSecret }) => {
        const {
          signInDetails: { loginId },
        } = await getCurrentUser();
        setCodeTOTP(sharedSecret);
        const authCode =
          "otpauth://totp/DocG AI:" +
          loginId +
          "?secret=" +
          sharedSecret +
          "&issuer=DocG AI";
        setAuthCode(authCode);
      })
      .catch((err) => {
        console.log(err);
        snackbar({
          message: err.message || "Unable to switch MFA option",
          color: "danger",
        });
      });
    setLoading(false);
  };

  const changeToTOTP = () => {
    if (answer === "") {
      snackbar({ message: "Code can't be empty", color: "danger" });

      return;
    }
    setLoading(true);
    setCodeTOTP(null);
    verifyTOTPSetup({ code: answer }).then(() => {
      changePreferredMFA("TOTP");
    });
    setLoading(false);
  };

  return (
    <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
      <div>
        {title && (
          <Card.Title className="d-flex align-items-center justify-content-between">
            Preferred MFA{" "}
          </Card.Title>
        )}
        <Card.Body className="">
          <div className="custom-repeat-checkbox-cont d-flex gap-3 px-4">
            <Checkbox
              value="mfa"
              label="No MFA"
              checked={mfa === "NOMFA"}
              onChange={() => {
                changePreferredMFA("NOMFA");
              }}
            ></Checkbox>
            <Checkbox
              value="mfa"
              label="SMS"
              disabled
              checked={mfa === "SMS_MFA"}
              onChange={() => {
                changePreferredMFA("SMS");
              }}
            ></Checkbox>
            <Checkbox
              value="tnc"
              label="TOTP"
              checked={mfa === "SOFTWARE_TOKEN_MFA"}
              onChange={() => {
                mfa !== "SOFTWARE_TOKEN_MFA" && _setUpTOTP();
              }}
            ></Checkbox>
          </div>
        </Card.Body>

        {!loading && codeTOTP !== null && (
          <div>
            <span>Setup TOTP </span>
            <div numberOfLines={5}>
              Step 1 :
              <div>
                Enter the following code into your authentication application{" "}
              </div>
            </div>
            {authCode && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <QRCode
                  id="qr-gen"
                  value={authCode}
                  up
                  size={256}
                  level={"H"}
                  includeMargin={true}
                />
              </div>
            )}
            <span>Code : {codeTOTP}</span>

            <span>
              Step 2 :
              <span>
                Validate by entering the code generated by the application
              </span>
            </span>

            <div>
              <Input
                label="Enter code *"
                onChange={(input) => setAnswer(input.target.value)}
                value={answer}
              />
            </div>
            <div>
              <button
                className="docg-save-btn"
                onClick={() => {
                  changeToTOTP();
                }}
              >
                Verify
              </button>
            </div>
          </div>
        )}

        <Loader show={loading} />
      </div>
    </Card>
  );
};
