import React from "react";
import Card from "src/reusable/Card";
import { Input, Select } from "src/reusable/Form";
import { PrimarySubmitButton } from "src/reusable/Buttons";
import { withFormik } from "formik";
import * as Yup from "yup";

import { addAccountManager } from "./api";

const phoneRegExp = /^(\+91)[6789][0-9]{9}$/;

const data = [
  { text: "Sub Admin", value: "SubAdmin" },
  { text: "Account Manager", value: "AccountManager" },
];

const AccountManagerSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("*Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("*Required"),
  email: Yup.string().email("Invalid email").required("*Required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Enter Correct Phone No. starting with +91")
    .required("*Required"),
  groups: Yup.string(),
});

const AccountManagerForm = ({ handleSubmit, isSubmitting }) => {
  return (
    <Card title="Account Manager Form">
      <form className="pt-2 pb-3" onSubmit={handleSubmit}>
        <div className="mbsc-grid">
          <div className="mbsc-row">
            <div className="mbsc-col-6">
              <Input label="First Name*" name="firstName" type="text" />
            </div>
            <div className="mbsc-col-6">
              <Input label="Last Name*" name="lastName" type="text" />
            </div>
          </div>
          <div className="mbsc-row mt-3">
            <div className="mbsc-col-6">
              <Input label="Email" name="email" type="text" />
            </div>
            <div className="mbsc-col-6">
              <Input label="Phone Number" name="phoneNumber" type="text" />
            </div>
          </div>
          <div className="mbsc-row mt-3">
            <div className="mbsc-col-12">
              <Select data={data} name="groups" label="Role*" />
            </div>
          </div>
          <div className="mbsc-row mt-3">
            <PrimarySubmitButton isSubmitting={isSubmitting} />
          </div>
        </div>
      </form>
    </Card>
  );
};

const AccountManager = withFormik({
  mapPropsToValues: () => ({
    firstName: "",
    lastName: "",
    groups: "",
    phoneNumber: "",
    email: "",
  }),

  // Custom sync validation
  validationSchema: AccountManagerSchema,

  handleSubmit: async (values, { resetForm, setSubmitting }) => {
    setSubmitting(false);
    const res = await addAccountManager(values);
    if (res) resetForm();
  },

  displayName: "BasicForm",
})(AccountManagerForm);

export default AccountManager;
