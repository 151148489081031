import { gql } from "@apollo/client";

export const USER_DATA = gql`
  query MyQuery {
    getUserInfo(isDocGEmployee: true) {
      message
      statusCode
      data {
        profileId
        roles
      }
    }
  }
`;

export const LIST_DIAGNOSIS_TEMPLATES = gql`
  query MyQuery($search: String!, $clinicId: String) {
    listDiagnosisTemplates(search: $search, clinicId: $clinicId) {
      message
      statusCode
      data {
        diagnosisName
        specialization
      }
    }
  }
`;
export const LIST_CLINIC_REQUEST = (take, filters) => gql`
  query MyQuery {
    listClinicCreationRequests(take: ${take}, orderBy:"createdAt=-1", filters:"${filters}") {
      data {
        approvalStatus
        clinicAddress
        clinicName
        cancelledCheque
        email
        firstName
        id
        lastName
        phoneNumber
        title
        createdAt
        registrationNumber
        gstin
        paymentGatewayType
        bankAccountNumber
        ifscCode
        contract
        subscriptionType
      }
      message
      totalCount
    }
  }
`;

export const LIST_MEDICINES = gql`
  query MyQuery($composition: String!, $name: String!) {
    listMedicine(composition: $composition, name: $name, take: 10) {
      data {
        name
        composition
        id
        manufacturer
        packingType
      }
      totalCount
    }
  }
`;

export const LIST_PRESCRIPTION_TEMPLATES = gql`
  query MyQuery($clinicId: String, $search: String!, $filters: String!) {
    listDiagnosisPrescriptionTemplates(
      clinicId: $clinicId
      search: $search
      filters: $filters
    ) {
      data {
        diagnosisTemplate
        prescriptionTemplateName
        drugs {
          additionalAdvice
          composition
          doseFrequency
          dose
          drugName
          drugType
          duration
          gapBetweenDosage
          gapBetweenDosageType
          id
          instruction
          period
        }
      }
      message
      statusCode
      totalCount
    }
  }
`;

export const LIST_LAB_TEST_TEMPLATES = gql`
  query MyQuery($clinicId: String, $search: String!, $filters: String!) {
    listDiagnosisLabTestTemplates(
      clinicId: $clinicId
      search: $search
      filters: $filters
    ) {
      data {
        diagnosisTemplate
        templateName
        tests {
          id
          instructions
          optionalInstructions
          testName
        }
      }
      message
      statusCode
      totalCount
    }
  }
`;
export const GET_CLINIC_PROFILE = gql`
  query MyQuery($data: GetClinicProfileRequest!) {
    getClinicProfile(data: $data) {
      data {
        address
        contactDetails
        specialities
        logoLink
        mail
        name
        id
        helplineNumber
        emergencyNumber
        currentSubscriptionId
        previousSubscriptions
      }
      statusCode
      message
    }
  }
`;

export const LIST_CLINICS = (take) => gql`
  query MyQuery {
    listAllClinics(take: ${take}, orderBy:"createdAt=-1") {
      data {
        address
        createdAt
        id
        logoLink
        mail
        name
        contactDetails
        helplineNumber
        status
      }
      message
      statusCode
      totalCount
    }
  }
`;
export const LIST_VACCINATION_TEMPLATES = (search) => gql`
  query MyQuery {
    listVaccinationTemplates(take: 15, search: "${search}") {
      data {
        vaccinationName
        site
        route
        purpose
        dose
        age
        ageUnit
        id
      }
      statusCode
    }
  }
`;
export const LIST_ALL_RAZORPAY_PLANS = gql`
  query MyQuery {
    listAllRazorpayPlans(orderBy: "activePlan=-1") {
      data {
        activePlan
        createdAt
        interval
        period
        subscriptionType
        planId
        item {
          amount
          currency
          description
          name
          unitAmount
          updatedAt
          type
        }
        applicableTaxVerbiage
        companyPercentage
        maxPatientsAllowed
        minPatientsAllowed
        unit
        verbiageForTransactFee
      }
      message
      statusCode
    }
  }
`;
export const LIST_ACTIVE_RAZORPAY_PLANS = gql`
  query MyQuery {
    listAllRazorpayPlans(orderBy: "createdAt=-1", isActive: true) {
      data {
        activePlan
        createdAt
        interval
        period
        subscriptionType
        planId
        item {
          amount
          currency
          description
          name
          unitAmount
          updatedAt
          type
        }
        applicableTaxVerbiage
        companyPercentage
        maxPatientsAllowed
        minPatientsAllowed
        unit
        verbiageForTransactFee
      }
      message
      statusCode
    }
  }
`;

export const FETCH_SUBSCRIPTION_BY_ID = gql`
  query MyQuery($data: String!) {
    fetchSubscriptionById(subscriptionId: $data) {
      data {
        authAttempts
        chargeAt
        clinicId
        createdAt
        currentEnd
        currentStart
        customerNotify
        endAt
        endedAt
        entity
        expireBy
        hasScheduledChanges
        notes {
          notesKey1
          notesKey2
        }
        paidCount
        offerId
        planId
        quantity
        remainingCount
        shortUrl
        signatureVerified
        startAt
        status
        subscriptionId
        totalCount
      }
      message
      statusCode
    }
  }
`;

export const FETCH_SUBSCRIPTION_PLAN_BY_ID = gql`
  query MyQuery($data: String!) {
    fetchSubscriptionPlanById(planId: $data) {
      data {
        activePlan
        appointmentsAllowed
        applicableTaxVerbiage
        companyPercentage
        maxPatientsAllowed
        minPatientsAllowed
        unit
        verbiageForTransactFee
        createdAt
        docgPercentage
        doctorsAllowed
        entity
        interval
        item {
          active
          amount
          createdAt
          currency
          description
          itemId
          name
          type
          unitAmount
          updatedAt
        }
        period
        planId
        razorpayPercentage
        subscriptionType
      }
      message
      statusCode
    }
  }
`;

export const INVITE_PATIENTS_LIST = gql`
  query MyQuery($take: Int) {
    listClinicLead(take: $take) {
      data {
        clinicName
        doctorName
        email
        id
        initialContactDate
        latestContactDate
        phoneNumber
        requestRejected
        requestSend
        status
      }
      message
      totalCount
    }
  }
`;

export const GET_FILE_FOR_DOCG = gql`
  query MyQuery($fileType: GenericUploadFileType!) {
    getFileForDocg(fileType: $fileType) {
      data {
        fileType
        name
        previousFileNames
        termsFor
        url
      }
      message
      statusCode
    }
  }
`;

export const GET_FILE_FOR_CLINIC = gql`
  query MyQuery($fileType: GenericUploadFileType!) {
    getGenericTermsFileForClinic(fileType: $fileType) {
      data {
        fileType
        name
        previousFileNames
        termsFor
        url
      }
      message
      statusCode
    }
  }
`;

export const LIST_CLINIC_DELETE_REQUEST = gql`
  query MyQuery {
    listClinicDeletionRequests {
      message
      statusCode
      totalCount
      data {
        clinicId
        clinicName
        createdAt
        dataStatus
        deletedDate
        deletionStatus
        downloadedDate
        id
        mail
        notes
        removalContractAccepted
        requestedBy
        requestedIP
        updatedAt
      }
    }
  }
`;
