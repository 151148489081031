import { Select, snackbar } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useQuery } from "react-query";
import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import Loader from "src/components/Dashboard/common/Loader";
import { REMOVE_CLINIC } from "src/components/graphql/GraphQLmutation";
import { LIST_CLINIC_DELETE_REQUEST } from "src/components/graphql/GraphQLquery";

const ClinicDeletion = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
    minHeight: 200,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {
    minHeight: 200,
  };

  const ActionCellRenderer = (params) => {
    const onRemoveClinic = async (status) => {
      try {
        const res = await postMutation(
          REMOVE_CLINIC,
          {
            clinicId: params?.data?.clinicId,
            deletionStatus: status,
          },
          true
        );
        if (
          res?.errors ||
          !(
            res?.data?.removeClinic?.statusCode < 300 &&
            res?.data?.removeClinic?.statusCode >= 200
          )
        ) {
          throw new Error(res?.data?.removeClinic?.message);
        } else {
          snackbar({
            message: "Clinic Deletion Request Approved Successfully!",
            color: "success",
          });
          return true;
        }
      } catch (err) {
        snackbar({
          message:
            err?.message ||
            "Some error occured while Adding Account. Try again",
          color: "danger",
        });
        return false;
      }
    };

    return (
      <div className="d-flex">
        <button
          onClick={() => onRemoveClinic("Approved")}
          className="docg-outline-btn p-1"
        >
          Approve
        </button>
        <button
          onClick={() => onRemoveClinic("Denied")}
          className="docg-outline-btn"
        >
          Deny
        </button>
      </div>
    );
  };

  const { data, refetch, isLoading } = useQuery(
    ["list all clinic deleted"],
    async function () {
      try {
        const res = await getQuery(LIST_CLINIC_DELETE_REQUEST);
        if (
          res?.errors ||
          !(
            res?.data?.listClinicDeletionRequests?.statusCode < 300 &&
            res?.data?.listClinicDeletionRequests?.statusCode >= 200
          )
        ) {
          throw new Error(
            "error",
            res?.data?.listClinicDeletionRequests?.message
          );
        } else {
          return res?.data?.listClinicDeletionRequests?.data;
        }
      } catch (err) {
        snackbar({
          message:
            err?.message ||
            "Some error occured while Adding Account. Try again",
          color: "danger",
        });
      }
    }
  );

  return (
    <div className="w-100">
      {" "}
      <>
        <Loader show={isLoading} />
        <Card className="docg-card">
          <Card.Header className="customCardHeader d-flex justify-content-between">
            <Card.Title className="customCardTitle">
              Clinic Deletion Request
            </Card.Title>
            <button onClick={() => refetch()} className="docg-save-btn">
              Refresh
            </button>
          </Card.Header>
          <Card.Body className="docg-card-body p-0">
            <div className="records-display">
              <span>Showing&nbsp;</span>
              <label className="record-selection-dropdown" htmlFor="">
                {totalRecords >= 0 && (
                  <Select
                    touchUi={false}
                    onChange={onPageSizeChanged}
                    value={displayRecords}
                    // data={data.length}
                  />
                )}
              </label>
              <span>&nbsp;Records</span>
            </div>
            <div
              className="ag-theme-alpine"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <AgGridReact
                rowData={data}
                defaultColDef={defaultColDef}
                rowHeight={75}
                frameworkComponents={{
                  actionCellRenderer: ActionCellRenderer,
                }}
                defaultColGroupDef={defaultColGroupDef}
                enableRangeSelection={true}
                pagination={true}
                paginationPageSize={displayRecords}
                onGridReady={onGridReady}
                animateRows={true}
                suppressAggFuncInHeader={true}
                suppressColumnVirtualisation={true}
              >
                <AgGridColumn
                  headerName="Requested By"
                  field="requestedBy"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Clinic Name"
                  field="clinicName"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Request Date & Time"
                  field="createdAt"
                  valueFormatter={(params) => {
                    if (params.value)
                      return new Date(params.value).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hours: "2-digit",
                        minutes: "2-digit",
                      });
                    return "-";
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="IP Address"
                  field="requestedIP"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Clinic email"
                  field="mail"
                ></AgGridColumn>
                <AgGridColumn headerName="Notes" field="notes"></AgGridColumn>
                <AgGridColumn
                  headerName="Deletion Request"
                  field="deletionStatus"
                  cellRenderer="actionCellRenderer"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Status"
                  field="dataStatus"
                ></AgGridColumn>
              </AgGridReact>
            </div>
          </Card.Body>
        </Card>
      </>
    </div>
  );
};

export default ClinicDeletion;
