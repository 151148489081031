import React, { Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PatientSidebar from "./PatientSidebar";
import PatientTabs from "./PatientTabs";
import {
  initialState,
  patientDetailsReducer,
} from "./store/PatientDetailsReducer";
import { StoreProvider } from "./store/Store";

const PatientDetail = (props) => {
  const { patientId } = useParams();

  return (
    <Fragment>
      {/* {patientId} {query.get("appointmentId")} */}
      <StoreProvider
        initialState={initialState}
        reducer={patientDetailsReducer}
      >
        <Helmet>
          <title>Patient Detail | DOCG</title>
        </Helmet>
        <div className="patient-wrapper docg-vh-standard">
          {patientId ? (
            <div className="">
              <PatientSidebar />
            </div>
          ) : null}
          <div className="patient-tab">
            <PatientTabs />
          </div>
        </div>
      </StoreProvider>
    </Fragment>
  );
};

export default PatientDetail;
