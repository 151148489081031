import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import tncIcon from "src/assets//tnc.svg";
import dashboard from "src/assets/icons/DashboardIcon.svg";
import LogoImage from "src/assets/plus-logo.svg";
import walletWhite from "src/assets/walletWhite.svg";
import clinicStatusIcon from "../assets/clinic-status-white.svg";
import clinicTemplateIcon from "../assets/clinic-template-white.svg";
import addAccountIcon from "../assets/patient/addAccount.svg";

const LeftSidebar = ({ isSideBarVisible, side, Patient, PatientRecord }) => {
  const userRole = sessionStorage.getItem("userRole");
  return (
    <Fragment>
      <div
        className={`sidebar ${
          isSideBarVisible ? "docg-hide" : "width-decrease-sidebar docg-show"
        }`}
        id="sidebar-docg"
        data-id={isSideBarVisible}
      >
        <div className="sidebar-wrapper">
          <ul className="nav">
            {["SuperAdmin"].find((role) => role === userRole) && (
              <li tooltip="Admin" className="nav-item">
                <NavLink to="/admin" exact className="nav-link navLink ">
                  <img className="addIcon" src={dashboard} alt="" />
                  {isSideBarVisible ? <p className="">Admin</p> : <></>}
                </NavLink>
              </li>
            )}
            {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Admin" className="nav-item">
                <NavLink
                  to="/rejected-clinics"
                  exact
                  className="nav-link navLink "
                >
                  <img className="addIcon" src={dashboard} alt="" />
                  {isSideBarVisible ? (
                    <p className="">Rejected Clinics</p>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
            )}

            {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Clinic Details" className="nav-item">
                <NavLink to="/clinics" className="nav-link">
                  <img className="addIcon" src={clinicTemplateIcon} alt="" />
                  {isSideBarVisible ? (
                    <p className="">Clinic Details</p>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
            )}
            {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Global templates" className="nav-item">
                <NavLink to="/global-template" className="nav-link">
                  <img className="addIcon" src={clinicTemplateIcon} alt="" />
                  {isSideBarVisible ? (
                    <p className="">Global Templates</p>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
            )}
            {/* {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Clinic Details" className="nav-item">
                <NavLink to="/clinic-actions" className="nav-link">
                  <img className="addIcon" src={clinicTemplateIcon} alt="" />
                  {isSideBarVisible ? (
                    <p className="">Clinic Actions</p>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
            )} */}
            {["SuperAdmin", "SubAdmin"].find((role) => role === userRole) && (
              <li tooltip="Account Manager" className="nav-item">
                <NavLink to="/add-account-manager" className="nav-link">
                  <img className="addIcon" src={addAccountIcon} alt="" />
                  {isSideBarVisible ? (
                    <p className="">Add Account Manager</p>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
            )}
            {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Invite Clinic" className="nav-item">
                <NavLink to="/invite-clinic" className="nav-link">
                  <img className="addIcon" src={clinicStatusIcon} alt="" />
                  {isSideBarVisible ? <p className="">Invite Clinic</p> : <></>}
                </NavLink>
              </li>
            )}
            {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Subscription Plans" className="nav-item">
                <NavLink to="/subscription-plans" className="nav-link">
                  <img className="addIcon" src={walletWhite} alt="" />
                  {isSideBarVisible ? (
                    <p className="">Subscription Plans</p>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
            )}
            {["SuperAdmin", "SubAdmin", "AccountManager"].find(
              (role) => role === userRole
            ) && (
              <li tooltip="Terms & Conditions" className="nav-item">
                <NavLink to="/tnc" className="nav-link">
                  <img className="addIcon" src={tncIcon} alt="" />
                  {isSideBarVisible ? <p className="">TnC Settings</p> : <></>}
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div
          className="sidebar-footer d-flex align-items-center justify-content-center flex-column"
          style={{ marginBottom: "0" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>Powered by: </h3>
            <img style={{ width: "4rem" }} src={LogoImage} alt="" />{" "}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LeftSidebar;
