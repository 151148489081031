import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { generateClient } from "@aws-amplify/api";
import { fetchAuthSession } from "@aws-amplify/auth";
import { createAuthLink, AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";


const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT;
const region = process.env.REACT_APP_REGION;

const auth = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () =>
    (
      await fetchAuthSession({ forceRefresh: true })
    ).tokens?.idToken?.toString(),
};

const link = ApolloLink.from([
  // @ts-ignore
  createAuthLink({ url, region, auth }),
  // @ts-ignore
  createHttpLink({ uri: url }),
]);

const httpLink = createHttpLink({ uri: url });

const _link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

export const subClient = generateClient();

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

const subscriptionClient = new ApolloClient({
  link: _link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export async function getQuery(query, data, general = false) {
  try {
    const response = await client.query({
      variables: general ? { ...data } : { data },
      query: query,
    });
    return response;
  } catch (e) {
    console.log("ERROR AT QUERY " + e);
  }
}

export async function postMutation(query, data, general = false) {
  try {
    const response = await client.mutate({
      variables: general ? { ...data } : { data },
      mutation: query,
    });
    console.log(response);
    return response;
  } catch (e) {
    console.log("ERROR AT MUTATION " + e);
  }
}

export async function subscription(query, variables = {}, next, error) {
  try {
    const response = subscriptionClient
      .subscribe({ query: query, variables })
      .subscribe({
        next,
        error,
        complete() {
          console.log("subs. DONE");
        },
      });

    return response;
    // return setTimeout(response.unsubscribe(),1000);
  } catch (e) {
    console.error("Error in subscription", e);
  }
}

export default client;
