import blobContentToBase64 from "src/lib/blobContentToBase64";
import getFilenameFromS3Link from "src/lib/getFilenameFromS3Link";
async function downloadFile(url, params) {
  const r = await fetch(url);
  const blob = await r.blob();
  const base64 = await blobContentToBase64(blob);
  const link = document.createElement("a");
  const linkSource = base64;
  let file_name = getFilenameFromS3Link(url);
  let dot = file_name.lastIndexOf(".");
  let ext = file_name.slice(dot);
  file_name =
    params?.data?.clinicName +
    "_" +
    new Date(params.data.createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hours: "2-digit",
      minutes: "2-digit",
    }) +
    ext;
  link.href = linkSource;
  link.download = file_name;
  link.click();
  link.remove();
}
const BankingDetails = (params) => {
  return (
    <>
      {params?.data?.bankAccountNumber ? (
        <div>
          <strong>Account no. :</strong>
          {params.data.bankAccountNumber}
          <br />
          <strong>IFSC Code :</strong>
          {params.data.ifscCode}
        </div>
      ) : (
        <button
          style={{ lineHeight: "25px" }}
          className="docg-patient-cancel-btn pt-1 pb-1"
          onClick={() => {
            downloadFile(params?.data?.cancelledCheque, params);
          }}
        >
          cheque
        </button>
      )}
    </>
  );
};

export default BankingDetails;
