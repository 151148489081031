import { Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Loader from "src/components/Dashboard/common/Loader";
import BankingDetails from "../Admin/BankingDetails";
import { listClinicCreationRequests } from "../Admin/api";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const Table = () => {
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(["00"]);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      ListAll(displayRecords);
      setReload(false);
    }
  }, [displayRecords, reload]);

  async function ListAll(displayRecords) {
    setLoader(true);
    try {
      let _displayRecords = +displayRecords;
      if (_displayRecords < 10) {
        _displayRecords = 10;
      }
      let res = await listClinicCreationRequests(
        _displayRecords,
        "approvalStatus=Rejected"
      );
      console.log(res);
      if (res.data) {
        setRowData(res.data);
        let value =
          res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
        console.log(value, res.totalCount);
        setData((prevProps) => {
          let arr = [];
          for (let index = 0; index <= res.totalCount; index++) {
            let value = index < 10 ? `0${index}` : index;
            arr.push(value);
          }
          return arr;
        });
        setDisplayRecords(value);
        setTotalRecords(res.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
    // let data = response.data.listClinicCreationRequests.data
    // return data
  }

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  //   setGridColumnApi(params.columnApi);
  // };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
    setReload(true);
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
    minHeight: 200,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {
    minHeight: 200,
  };

  return (
    <Fragment>
      <Loader show={loader} />
      <Card className="docg-card">
        <CardHeader className="customCardHeader d-flex justify-content-between">
          <CardTitle className="customCardTitle">
            Rejected Clinics for Account Verification
          </CardTitle>
          <button onClick={(e) => setReload(true)} className="docg-save-btn">
            Refresh
          </button>
        </CardHeader>
        <CardBody className="docg-card-body p-0">
          <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              {totalRecords >= 0 && (
                <Select
                  touchUi={false}
                  onChange={onPageSizeChanged}
                  value={displayRecords}
                  data={data}
                />
              )}
            </label>
            <span>&nbsp;Records</span>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={rowData}
              defaultColDef={defaultColDef}
              rowHeight={75}
              defaultColGroupDef={defaultColGroupDef}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={displayRecords}
              // onGridReady={onGridReady}
              animateRows={true}
              suppressAggFuncInHeader={true}
              suppressColumnVirtualisation={true}
              frameworkComponents={{
                bankingDetails: BankingDetails,
              }}
            >
              <AgGridColumn
                headerName="Name"
                field="firstName"
                headerTooltip="Name"
                tooltipValueGetter={(params) => {
                  let name = [
                    params.data.title,
                    params.data.firstName,
                    params.data.lastName,
                  ];
                  return name.filter((item) => item != null).join(" ");
                }}
                valueFormatter={(params) => {
                  let name = [
                    params.data.title,
                    params.data.firstName,
                    params.data.lastName,
                  ];
                  return name.filter((item) => item != null).join(" ");
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Mobile Number"
                field="phoneNumber"
                tooltipField="Mobile Number"
                headerTooltip="phoneNumber"
              ></AgGridColumn>
              <AgGridColumn
                initialPinned
                headerName="Email"
                field="email"
                tooltipField="Email"
                headerTooltip="email"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Clinic Name"
                field="clinicName"
                tooltipField="clinicName"
                headerTooltip="Clinic Name"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Medical Registration No."
                field="registrationNumber"
                tooltipField="registrationNumber"
                headerTooltip="Medical Registration No."
              ></AgGridColumn>
              <AgGridColumn
                headerName="Banking Details"
                field="bankingDetails"
                cellRenderer="bankingDetails"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Subscription Type"
                field="subscriptionType"
                headerTooltip="Subscription Type"
                tooltipField="subscriptionType"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Created At"
                field="createdAt"
                headerTooltip="Created At"
                tooltipValueGetter={(params) => {
                  if (params.value)
                    return new Date(params.value).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  return "-";
                }}
                valueFormatter={(params) => {
                  if (params.value)
                    return new Date(params.value).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  return "-";
                }}
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Table;
