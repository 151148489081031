import ClinicDataDownload from "./ClinicDataDownload";
import ClinicDeletion from "./ClinicDeletion";

const ClinicActions = () => {
  return (
    <div className="d-flex flex-column gap-3 w-100">
      <ClinicDeletion />
      <ClinicDataDownload />
    </div>
  );
};

export default ClinicActions;
