import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import "./Auth.scss";
import next_icon from "src/assets/Next icon.svg";
import doctor from "src/assets/Doctors 05.svg";

import grid from "src/assets/grid.svg";
import signin_circle from "src/assets/signin-circle.svg";
import resend_icon from "src/assets/Resend icon.svg";
import line from "src/assets/Lines.svg";
import OtpInput from "react-otp-input";

function ForgotPassword() {
  const [otp, setOtp] = useState();
  const [formErrors, setFormErrors] = useState();

  const resendOtp = () => {};

  const handleChange = (e) => {
    const value = e;
    setOtp(value);
    if (value.length != 6) {
      setFormErrors("Invalid OTP, OTP must be 6 digit");
    } else {
      setFormErrors();
      localStorage.setItem("OTP", value);
    }
  };

  const nextStep = () => {
    if (otp.length != 6) {
      setFormErrors("Invalid OTP");
    } else {
      window.location = "/reset-password";
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Forgot Password | DOCG</title>
      </Helmet>
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-lg-6 doctor-images-left mbsc-col-12">
                <div className="left-signup-div" id="reset-password-page"></div>
                <img src={doctor} className="left-signup-img left-steps-img" />
              </div>
              <div className="mbsc-col-lg-6 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 1/2</p>
                    <p className="get-txt mt-4">OTP Verification</p>
                    <p className="signup-link mt-2">
                      We'll text you on XXXXXX4321
                    </p>
                    <br />
                    {/* <mobiscroll.Input
                            inputStyle="underline"
                            passwordToggle={true}
                            placeholder="Enter your otp here"
                            type="number"
                            onChange={handleChange}
                            valid={!formErrors}
                            errorMessage={formErrors}
                          ></mobiscroll.Input> */}
                    <div className="signup-wrapper-inner">
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        hasErrored={formErrors}
                        errorStyle={"otp-input-error"}
                        separator={<span>&emsp;</span>}
                        containerStyle={"otp-container"}
                        inputStyle={"form-input-otp"}
                        isInputNum={true}
                      />
                      <br />
                      {formErrors ? (
                        <span className="otp-error">{formErrors}</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <button className="purple-button" onClick={nextStep}>
                        <img src={next_icon} />
                        &nbsp;&nbsp; Continue
                      </button>
                      <br />
                      <br />
                      <button className="purple-color">
                        <img src={resend_icon} className="resend-icon" />
                        Send me a new code
                      </button>
                    </div>
                  </div>
                </div>
                <img src={line} className="lines-bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ForgotPassword;
