// eslint-disable-next-line import/no-anonymous-default-export
export default function (blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((rs, rj) => {
    reader.onloadend = () => {
      rs(reader.result);
    };
    reader.onerror = rj;
  });
}
