export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
export const PATIENT_ID = "PATIENT_ID";
export const UNSAVED = "UNSAVED";

export const initialState = {
  activeTab: "diagnosis",
  patientId: null,
  unsaved: false,
};

export const setUnsaved = (value) => ({
  type: UNSAVED,
  unsaved: value,
});

export const isActiveTab = (value) => ({
  type: CHANGE_ACTIVE_TAB,
  activeTab: value,
});

export const setPatientId = (patientId) => ({
  type: PATIENT_ID,
  patientId,
});

export const patientDetailsReducer = (state, action, initialState) => {
  switch (action.type) {
    case CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case PATIENT_ID:
      return {
        ...state,
        patientId: action.patientId,
      };
    case UNSAVED:
      return {
        ...state,
        unsaved: action.unsaved,
      };
    default:
      return state;
  }
};
