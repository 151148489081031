import { Select, snackbar } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import Loader from "src/components/Dashboard/common/Loader";
import { INVITE_PATIENTS_LIST } from "src/components/graphql/GraphQLquery";
import BankingDetails from "../Admin/BankingDetails";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

async function listClinicLead(displayRecords) {
  try {
    let response = await getQuery(
      INVITE_PATIENTS_LIST,
      { take: displayRecords },
      true
    );

    if (typeof response === "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      if (response.data.listClinicLead.data) {
        snackbar({
          message: "Clinic Creation request Fetched Successfully",
          color: "success",
        });
        return response.data.listClinicLead;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching. Try again",
      color: "danger",
    });
    console.log("Error at listClinicLead: ", e);
    return { data: [] };
  }
}

const InvitedPatientsList = () => {
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(["00"]);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      ListAll(displayRecords);
      setReload(false);
    }
  }, [displayRecords, reload]);

  async function ListAll(displayRecords) {
    setLoader(true);
    try {
      let _displayRecords = +displayRecords;
      if (_displayRecords < 10) {
        _displayRecords = 10;
      }
      let res = await listClinicLead(
        _displayRecords,
        "approvalStatus=Rejected"
      );
      console.log(res);
      if (res.data) {
        setRowData(res.data);
        let value =
          res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
        console.log(value, res.totalCount);
        setData((prevProps) => {
          let arr = [];
          for (let index = 0; index <= res.totalCount; index++) {
            let value = index < 10 ? `0${index}` : index;
            arr.push(value);
          }
          return arr;
        });
        setDisplayRecords(value);
        setTotalRecords(res.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
    // let data = response.data.listClinicLead.data
    // return data
  }

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  //   setGridColumnApi(params.columnApi);
  // };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
    setReload(true);
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
    minHeight: 200,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {
    minHeight: 200,
  };

  return (
    <Fragment>
      <div className="mbsc-col-12 docg-dashboard docg-vh-standard d-flex flex-column">
        <div className="mbsc-row">
          <div className="d-flex mbsc-col-12 pb-2 pt-2">
            <Loader show={loader} />
            <Card className="docg-card">
              <CardHeader className="customCardHeader d-flex justify-content-between">
                <CardTitle className="customCardTitle">
                  Invited Clinics List
                </CardTitle>
                <button
                  onClick={(e) => setReload(true)}
                  className="docg-save-btn"
                >
                  Refresh
                </button>
              </CardHeader>
              <CardBody className="docg-card-body p-0">
                <div className="records-display">
                  <span>Showing&nbsp;</span>
                  <label className="record-selection-dropdown" htmlFor="">
                    {totalRecords >= 0 && (
                      <Select
                        touchUi={false}
                        onChange={onPageSizeChanged}
                        value={displayRecords}
                        data={data}
                      />
                    )}
                  </label>
                  <span>&nbsp;Records</span>
                </div>
                <div
                  className="ag-theme-alpine"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    rowHeight={75}
                    defaultColGroupDef={defaultColGroupDef}
                    enableRangeSelection={true}
                    pagination={true}
                    paginationPageSize={displayRecords}
                    // onGridReady={onGridReady}
                    animateRows={true}
                    suppressAggFuncInHeader={true}
                    suppressColumnVirtualisation={true}
                    frameworkComponents={{
                      bankingDetails: BankingDetails,
                    }}
                  >
                    <AgGridColumn
                      headerName="Mobile Number"
                      field="phoneNumber"
                    ></AgGridColumn>
                    <AgGridColumn
                      initialPinned
                      headerName="Email"
                      field="email"
                    ></AgGridColumn>
                    <AgGridColumn field="clinicName"></AgGridColumn>
                    <AgGridColumn field="doctorName"></AgGridColumn>
                    <AgGridColumn field="status"></AgGridColumn>
                    <AgGridColumn
                      field="initialContactDate"
                      valueFormatter={(params) => {
                        if (params.value)
                          return new Date(params.value).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hours: "2-digit",
                              minutes: "2-digit",
                            }
                          );
                        return "-";
                      }}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InvitedPatientsList;
