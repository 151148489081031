import { Input } from "@mobiscroll/react5";
import { useField } from "formik";
import PropTypes from "prop-types";

const FormikInput = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { error, touched } = meta;

  return (
    <>
      <Input
        inputStyle="underline"
        labelStyle="floating"
        placeholder=""
        {...field}
        {...props}
      />
      <span className="mbsc-error-message mbsc-ios">
        {touched && error ? error : ""}
      </span>
    </>
  );
};

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default FormikInput;
