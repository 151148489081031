import { useField } from "formik";
import PropTypes from "prop-types";
import { Select } from "@mobiscroll/react5";

const FormikSelect = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { onBlur } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  console.log(field);
  return (
    <>
      <Select
        inputProps={{
          inputStyle: "underline",
          labelStyle: "floating",
        }}
        touchUi={false}
        onChange={(e) => {
          setValue(e.value);
        }}
        onBlur={onBlur}
        value={field.value}
        {...props}
      />
      <span className="mbsc-error-message mbsc-ios">
        {touched && error ? error : ""}
      </span>
    </>
  );
};

FormikSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormikSelect;
