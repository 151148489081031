import React, { Component, useContext, useState, useEffect } from "react";
import LeftSidebar from "./LeftSidebar";
// import Sidebar from "./Sidebar";
import HeaderTop from "./HeaderTop";
import { ReactNotifications } from "react-notifications-component";
import { Redirect } from "react-router-dom";
import { snackbar } from "@mobiscroll/react5";
import { signOut, fetchMFAPreference } from "aws-amplify/auth";
import IdleTimer from "./IdleTimer";
const DoctorTemplate = (props) => {
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const [searchBar, setSearchBar] = useState(true);
  const [TimeBar, setTimeBar] = useState(true);

  async function _signOut() {
    try {
      signOut()
        .then(() => {
          snackbar({
            message: "You have been logged out",
            color: "danger",
          });
          localStorage.clear();
          sessionStorage.clear();
          window.location = "/";
        })
        .catch(() => {
          snackbar({
            message: "Unable to logout, Please Refresh.",
            color: "danger",
          });
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 15 * 60, //expire after 15 mins
      onTimeout: async () => {
        await _signOut();
      },
      onExpired: async () => {
        //do something if expired on load
        await _signOut();
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const status = sessionStorage.getItem("loginStatus");
  const roles = sessionStorage.getItem("userRole");
  console.log(status);

  React.useEffect(() => {
    if (window.innerWidth <= 1400) {
      setIsSideBarVisible(false);
    }
  }, []);

  // if there is not user, redirect to the home page
  if (!status) {
    return <Redirect to="/" />;
  }

  const handleSidebar = (...props) => {
    const currentSidebarState = isSideBarVisible;
    setIsSideBarVisible(!currentSidebarState);
  };

  return (
    <>
      <div className="wrapper">
        <LeftSidebar isSideBarVisible={isSideBarVisible} />
        {/* <Sidebar isSideBarVisible={isSideBarVisible} /> */}
        <ReactNotifications />

        <div className={`main-panel${isSideBarVisible ? "" : " min-sidebar"}`}>
          <HeaderTop
            handleSidebar={handleSidebar}
            searchBar={searchBar}
            TimeBar={TimeBar}
          />
          <div className="content">
            <div className="mbsc-grid">
              <div className="mbsc-row">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorTemplate;
