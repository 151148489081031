import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { GET_CLINIC_PROFILE } from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";

export async function getClinicProfile(data) {
  try {
    const response = await getQuery(GET_CLINIC_PROFILE, { id: data });
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.getClinicProfile?.statusCode >= 200 &&
        response?.data?.getClinicProfile?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Clinic Information fetched successfully.",
        color: "success",
      });
      if (response?.data?.getClinicProfile?.data) {
        return response?.data?.getClinicProfile?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return [];
  }
}
