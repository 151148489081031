import React from "react";
import { Route } from "react-router-dom";
import { useStore } from "src/store/Store";

const PrivateRoute = ({ component: Component, modules, key, ...rest }) => {
  const userRole = sessionStorage.getItem("userRole");
  console.log(userRole);
  if (modules.find((module) => module === userRole)) {
    return (
      <Route
        {...rest}
        exact
        key={key}
        render={(props) => <Component {...props} />}
      />
    );
  }
  else <></>;
};

export default PrivateRoute;
