import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import plus from "src/assets/icons/plusIcon.svg";
import { Card } from "react-bootstrap";

import { listGlobalDiagnosisTemplates } from "../prescription/api/index";
// import "../../patient.scss"
import "../prescription/prescription.scss";
import { debounce } from "lodash";
import { Input } from "@mobiscroll/react5";

const ListItem = ({ data }) => {
  console.log(data);
  return (
    <li className="list-item-select-vaccination">
      <div className="mbsc-grid">
        <div className="mbsc-row">
          <div className="mbsc-col-5">
            <span>{data.diagnosisName}</span>
          </div>
          <div className="mbsc-col-5">{data.specialization.join(", ")}</div>
          <div className="mbsc-col-2 d-flex justify-content-end pr-0">
            <img style={{ height: "2em", width: "" }} src={plus} alt="" />
          </div>
        </div>
      </div>
    </li>
  );
};

const SelectDiagnosis = ({ img, title, placeholder }) => {
  const [search, setSearch] = React.useState("");
  const [data, setData] = useState([]);

  const { patientId } = useParams();

  const listDiagnosis = async (search) => {
    try {
      let res = await listGlobalDiagnosisTemplates(search, patientId);
      setData(res);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const debounceFn = useCallback(debounce(listDiagnosis, 1000), [search]);
  React.useEffect(() => {
    debounceFn(search);
  }, [search]);

  return (
    <Card className="docg-card w-100" style={{ flex: 1 }}>
      <Card.Title className="d-flex justify-content-between">
        {" "}
        <div className="d-flex">
          <img src={img} alt="" />
          <span>{title}</span>
        </div>
        <button onClick={() => listDiagnosis(search)} className="docg-save-btn">
          Refresh
        </button>
      </Card.Title>
      <Card.Body className="d-flex flex-column">
        {/* <input type="text" placeholder={placeholder} onChange={handleChange} value={select} /> */}
        <Input
          inputStyle="underline"
          labelStyle="floating"
          placeholder={placeholder}
          name="doctorName"
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div className="docg-select-list">
          <ul>
            {data?.map((item) => (
              <ListItem data={item} />
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SelectDiagnosis;
