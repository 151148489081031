import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { LIST_CLINIC_REQUEST } from "src/components/graphql/GraphQLquery";
import {
  CLINIC_APPROVAL,
  CLINIC_REJECTION,
} from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";

export async function approveClinicCreationRequest(data) {
  try {
    const response = await postMutation(CLINIC_APPROVAL, data);

    if (typeof response === "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      if (
        response.data.approveClinicCreationRequest.statusCode < 300 &&
        response.data.approveClinicCreationRequest.statusCode >= 200
      ) {
        snackbar({
          message: "Doctor/Clinic Approved Successfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response.data.approveClinicCreationRequest.message);
      }
    }
  } catch (e) {
    snackbar({
      message: e.message ? e.message : "Something went wrong",
      color: "danger",
    });
    console.log("Error at approveClinicCreationRequest: ", e);
    return false;
  }
}
export async function rejectClinicCreationRequest(data) {
  try {
    const response = await postMutation(CLINIC_REJECTION, data);

    if (typeof response === "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      if (
        response.data.rejectClinicCreationRequest.statusCode < 300 &&
        response.data.rejectClinicCreationRequest.statusCode >= 200
      ) {
        snackbar({
          message: "Doctor/Clinic Rejected Successfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response?.data?.rejectClinicCreationRequest.message);
      }
    }
  } catch (e) {
    if (e.message.length > 200) {
      snackbar({
        message: "Some error occured while Rejecting. Try again",
        color: "danger",
      });
    } else
      snackbar({
        message: e.message,
        color: "danger",
      });

    console.log("Error at rejectClinicCreationRequest: ", e);
    return false;
  }
}
export async function listClinicCreationRequests(displayRecords, filters) {
  try {
    let response = await getQuery(LIST_CLINIC_REQUEST(displayRecords, filters));

    if (typeof response === "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      if (response.data.listClinicCreationRequests.data) {
        snackbar({
          message: "Clinic Creation request Fetched Successfully",
          color: "success",
        });
        return response.data.listClinicCreationRequests;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching. Try again",
      color: "danger",
    });
    console.log("Error at listClinicCreationRequests: ", e);
    return { data: [] };
  }
}
