import { snackbar } from "@mobiscroll/react5";
import { ADD_CLINIC_LEAD } from "src/components/graphql/GraphQLmutation";
import { postMutation } from "src/components/ApolloConnection/ApolloProviderComponent";

export async function addClinicLead(data) {
  console.log(data);
  try {
    let response = await postMutation(ADD_CLINIC_LEAD, data);
    if (
      response.data !== null &&
      response?.data?.addClinicLead?.statusCode >= 200 &&
      response?.data?.addClinicLead?.statusCode < 300
    ) {
      snackbar({
        message: response?.data?.addClinicLead.message,
        color: "success",
      });
      return true;
    } else {
      throw new Error(response?.data?.addClinicLead.message);
    }
  } catch (e) {
    console.log(e.message ? e.message : "Something went wrong");
    snackbar({
      message: e.message,
      color: "danger",
    });
    return false;
  }
}
