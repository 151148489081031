import _ from "lodash";
import React, { Suspense } from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Loader from "src/components/Dashboard/common/Loader";

import { addClinicLead } from "./api/index";
import InvitedPatientsList from "./InvitedPatientsList";

const AddPatientGeneralInfo2 = React.lazy(() =>
  import("./AddPatientGeneralInfo2")
);

const initialValues = {
  clinicName: "",
  doctorName: "",
  email: "",
  phoneNumber: "",
};

const required = {
  clinicName: {
    required: true,
    regex: null,
    error: "Please enter Clinic Name",
    validationError: "Clinic name is required",
  },
  doctorName: {
    required: true,
    regex: null,
    error: "Doctor Name is required",
  },
  email: {
    required: false,
    regex:
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    validationError: "Please enter Valid Email Address",
    error: "Please Enter Email Address",
  },
  phoneNumber: {
    required: false,
    regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
    validationError: "Please enter Valid 10 digit Number",
    error: "Please Enter 10 digit Mobile Number",
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      let value = action.payload.target.value;

      return {
        ...state,
        [action.payload.target.name]: value,
      };
    case "CUSTOM":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET":
      return {
        ...initialValues,
      };
    default:
      return state;
  }
};
const handleValidation = (formValues, setErrors) => {
  let isValid = true;
  let errors = new Object();

  Object.keys(formValues).map(function (key, index) {
    if (formValues[key] == null) {
      if (required[key] && required[key].required) {
        errors[key] = required[key].error;

        isValid = false;
      }
    } else {
      let re = null;
      if (required[key]?.regex) {
        re = new RegExp(required[key].regex);
      }
      if (_.trim(formValues[key]) == "" || (re && !re.test(formValues[key]))) {
        errors[key] = required[key].error;
        isValid = false;
      }
    }
  });

  if (formValues.phoneNumber == null) {
    errors["phoneNumber"] = "Phone Number must be provided";
    isValid = false;
  }

  if (formValues.email == null) {
    errors["email"] = "Email must be provided";
    isValid = false;
  }

  console.log(errors, isValid);
  setErrors(errors);
  return { isValid };
};

const AddPatient2 = () => {
  const [formValues, dispatchForm] = React.useReducer(reducer, initialValues);
  const [errors, setErrors] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const { clinicId } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let res = handleValidation(formValues, setErrors);
    if (res.isValid) {
      let data = formValues;

      if (data.phoneNumber) {
        data.phoneNumber = "+91" + data.phoneNumber;
      }

      data.clinicId = clinicId;
      setLoader(true);
      const res = await addClinicLead(data);

      if (res) {
        //   history.push(`/patient-record/${res.id}`)
        setErrors(null);
        console.log(res);
      }
      dispatchForm({ type: "RESET" });
      setLoader(false);
    }
  };

  return (
    <>
      <Loader show={loader} />
      <Helmet>
        <title>Invite Patient | DOCG</title>
      </Helmet>

      <Suspense fallback={<div>Loading...</div>}>
        <Card className="docg-card m-4 h-100">
          <Card.Header
            className="d-flex justify-content-between customCardHeader"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <Card.Title className="docg-card-title customCardTitle">
              Invite New Clinic
            </Card.Title>
          </Card.Header>
          <Card.Body className="docg-card-body pb-4 pt-4">
            <AddPatientGeneralInfo2
              errors={errors}
              formValues={formValues}
              dispatchForm={dispatchForm}
              handleSubmit={handleSubmit}
            />
          </Card.Body>
        </Card>
      </Suspense>
      <InvitedPatientsList />
    </>
  );
};

export default AddPatient2;
