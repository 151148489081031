import React, { Fragment, useState, Suspense } from "react";
import { Modal } from "react-bootstrap";
import { Select, Input } from "@mobiscroll/react5";
import Loader from "src/components/Dashboard/common/Loader";
import {
  approveClinicCreationRequest,
  rejectClinicCreationRequest,
} from "./api";
import ApprovalPopup from "./ApprovalPopup";
import RejectionPopup from "./RejectionPopup";

const ActionCellRender = (props) => {
  const { params, setReload } = props;

  const [value, setValue] = React.useState("NA");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [rejectionPopup, setRejectionPopup] = React.useState(false);
  const [approvalPopup, setApprovalPopup] = React.useState(false);

  async function Approve(e) {
    setApprovalPopup(true);
  }

  async function Cancel(e) {
    setRejectionPopup(true);
  }

  const handleReject = React.useCallback(
    async (e) => {
      e.preventDefault();
      let msg = message?.trim() == "" ? undefined : message;
      if (msg == undefined) {
        setError(true);
      } else {
        setError(false);
        setLoader(true);
        rejectClinicCreationRequest({
          requestId: params.value,
          message: msg,
        })
          .then(() => {
            console.log(`Task Number One`);
          })
          .catch(() => {
            console.log(`Task in Error`);
          })
          .finally(() => {
            setLoader(false);
            closeRejectionPopup();
          });
      }
    },
    [message, rejectClinicCreationRequest, params]
  );

  const closeRejectionPopup = React.useCallback(() => {
    setRejectionPopup(false);
  }, []);

  const closeApprovalPopup = React.useCallback(() => {
    setApprovalPopup(false);
  }, []);

  return (
    <>
      <Loader show={loader} />
      <div className="d-flex align-items-center">
        <Select
          inputProps={{
            inputStyle: "underline",
            labelStyle: "floating",
          }}
          touchUi={false}
          onChange={(e) => {
            setValue(e.value);
          }}
          display="anchored"
          value={value}
          data={[
            { text: "Doctor", value: "Doctor" },
            { text: "NA", value: "NA" },
          ]}
          placeholder="Additional Role"
          style={{ width: "100px" }}
          width="100"
          maxWidth="100"
        />
        <div className="pt-2 pb-0 d-flex">
          {" "}
          <button
            className="docg-btn success mr-2"
            id={params.value}
            onClick={(e) => Approve(e)}
          >
            Approve
          </button>
          <button className="docg-btn danger" onClick={(e) => Cancel(e)}>
            Reject
          </button>
        </div>
      </div>
      <ApprovalPopup
        approvalPopup={approvalPopup}
        closeApprovalPopup={closeApprovalPopup}
        params={params}
        value={value}
        setReload={setReload}
      />
      <RejectionPopup
        rejectionPopup={rejectionPopup}
        closeRejectionPopup={closeRejectionPopup}
        params={params}
        value={value}
        setReload={setReload}
      />
    </>
  );
};

export default ActionCellRender;
