import React, { Fragment, useState } from "react";
import DashobardIcon from "../assets/icons/Dashboard.svg";
import SignOutIcon from "../assets/icons/SignOutIcon.svg";
import { Link } from "react-router-dom";
import { snackbar } from "@mobiscroll/react";
import { signOut } from "@aws-amplify/auth";

const HeaderTop = ({ handleSidebar, searchBar, TimeBar }) => {
  async function WebSignOut() {
    try {
      signOut()
        .then(() => {
          snackbar({
            message: "You have been logged out",
            color: "danger",
          });
        })
        .catch(() => {
          snackbar({
            message: "Unable to logout, Please Refresh.",
            color: "danger",
          });
        });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-transparent fixed-top navbar-fixed-top">
        <div className="container-fluid d-md-flex">
          <button
            type="button"
            className="btn btn-link mr-1"
            onClick={() => {
              handleSidebar(false);
            }}
          >
            <img src={DashobardIcon} alt="" />
          </button>
          <div className="docg-top-bar-left  jc-sb">
            <ul className="navbar-nav  align-items-center">
              <li className="nav-item">
                <button onClick={WebSignOut} title="Logout" className="nav-link">
                  <img src={SignOutIcon} alt="" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default HeaderTop;
