import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {} from "src/components/graphql/GraphQLquery";
import { ADD_ACCOUNT_MANAGER } from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";

export async function addAccountManager(data) {
  try {
    const response = await postMutation(ADD_ACCOUNT_MANAGER, data);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.addAccountManager?.statusCode < 300 &&
        response?.data?.addAccountManager?.statusCode >= 200
      )
    ) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Account Added successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Adding Account. Try again",
      color: "danger",
    });
    console.log("Error at addAccountManager: ", e);
    return false;
  }
}
