import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { LIST_CLINICS } from "src/components/graphql/GraphQLquery";
import {
  ACTIVATE_CLINIC,
  DEACTIVATE_CLINIC,
} from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";

export async function listClinics(take) {
  try {
    const response = await getQuery(LIST_CLINICS(take));
    if (typeof response == "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Patient Information fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listAllClinics?.data) {
        return response?.data?.listAllClinics;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching patient information ", e);
    return { data: [] };
  }
}

export async function activateClinic(data) {
  try {
    const response = await postMutation(ACTIVATE_CLINIC, data);
    if (typeof response == "undefined" || response.errors) {
      throw new Error("error", response);
    } else {
      if (
        !(
          response?.data?.activateClinic?.statusCode >= 200 &&
          response?.data?.activateClinic?.statusCode < 300
        ) ||
        response?.data?.activateClinic?.errors
      ) {
        throw new Error("error", response?.data?.activateClinic.errors);
      }
      snackbar({
        message: "Clinic Activated successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    let message =
      "Some error occured while activating clinic. Please try again later.";
    if (e.message.length < 200) {
      message = e.message;
    }
    snackbar({
      message,
      color: "danger",
    });
    console.log("Error at Clinic List: ", e);
    return false;
  }
}
export async function deactivateClinic(data) {
  try {
    const response = await postMutation(DEACTIVATE_CLINIC, data);
    if (typeof response == "undefined" || response.errors) {
      throw new Error("error", response);
    } else {
      if (
        !(
          response?.data?.deactivateClinic?.statusCode >= 200 &&
          response?.data?.deactivateClinic?.statusCode < 300
        ) ||
        response?.data?.deactivateClinic?.errors
      ) {
        throw new Error("error", response?.data?.deactivateClinic.errors);
      }
      snackbar({
        message: "Clinic Deactivated successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    let message =
      "Some error occured while deactivating Clinic. Please try again later.";
    if (e.message.length < 200) {
      message = e.message;
    }
    snackbar({
      message,
      color: "danger",
    });
    console.log("Error at Clinic List: ", e);
    return false;
  }
}
