import { useState, useEffect } from "react";

export const passwordValidation = ({
  firstPassword = "",
  secondPassword = "",
  requiredLength = 8,
}) => {
  const validLength = firstPassword.length >= requiredLength ? true : false;
  const upperCase = firstPassword.toLowerCase() !== firstPassword;
  const lowerCase = firstPassword.toUpperCase() !== firstPassword;
  const hasNumber = /\d/.test(firstPassword);
  const match = firstPassword && firstPassword === secondPassword;
  const specialChar = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(
    firstPassword
  );

  return { validLength, hasNumber, upperCase, lowerCase, match, specialChar };
};
