import React from "react";
import ReactDOM from "react-dom";

const Loader = (props) => {
  const { show } = props;
  return ReactDOM.createPortal(
    <div style={{ display: show ? "block" : "none" }} className="loading">
      Loading&#8230;
    </div>,
    document.body
  );
};

export default Loader;
