import { Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Fragment, useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import Loader from "src/components/Dashboard/common/Loader";
import ActionCellRender from "./ActionCellRender";
import BankingDetails from "./BankingDetails";
import { listClinicCreationRequests } from "./api";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const Table = (props) => {
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(["00"]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(true);

  const [open, setOpen] = useState();

  useEffect(() => {
    if (reload) {
      ListAll(displayRecords);
      setReload(false);
    }
  }, [displayRecords, reload]);

  async function ListAll(displayRecords) {
    setLoader(true);
    try {
      let _displayRecords = +displayRecords;
      if (_displayRecords < 10) {
        _displayRecords = 10;
      }
      let res = await listClinicCreationRequests(
        _displayRecords,
        "approvalStatus=Pending"
      );
      console.log(res);
      if (res.data) {
        setRowData(res.data);
        let value =
          res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
        console.log(value, res.totalCount);
        setData((prevProps) => {
          let arr = [];
          for (let index = 0; index <= res.totalCount; index++) {
            let value = index < 10 ? `0${index}` : index;
            arr.push(value);
          }
          return arr;
        });
        setDisplayRecords(value);
        setTotalRecords(res.totalCount);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
    // let data = response.data.listClinicCreationRequests.data
    // return data
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords(e.valueText);
    setReload(true);
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
    minHeight: 200,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {
    minHeight: 200,
  };

  return (
    <Fragment>
      <Loader show={loader} />
      <Card className="docg-card">
        <CardHeader className="customCardHeader d-flex justify-content-between">
          <CardTitle className="customCardTitle">
            Account Verification
          </CardTitle>
          <button onClick={(e) => setReload(true)} className="docg-save-btn">
            Refresh
          </button>
        </CardHeader>
        <CardBody className="docg-card-body p-0">
          <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              {totalRecords >= 0 && (
                <Select
                  touchUi={false}
                  onChange={onPageSizeChanged}
                  value={displayRecords}
                  data={data}
                />
              )}
            </label>
            <span>&nbsp;Records</span>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={rowData}
              defaultColDef={defaultColDef}
              rowHeight={75}
              frameworkComponents={{
                actionCellRender: (params) => (
                  <ActionCellRender params={params} setReload={setReload} />
                ),
                bankingDetails: BankingDetails,
              }}
              defaultColGroupDef={defaultColGroupDef}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={displayRecords}
              onGridReady={onGridReady}
              animateRows={true}
              suppressAggFuncInHeader={true}
              suppressColumnVirtualisation={true}
            >
              <AgGridColumn
                headerName="Name"
                field="firstName"
                valueFormatter={(params) => {
                  let name = [
                    params.data.title,
                    params.data.firstName,
                    params.data.lastName,
                  ];
                  return name.filter((item) => item != null).join(" ");
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Mobile Number"
                headerTooltip="Mobile Number"
                field="phoneNumber"
                tooltipField="phoneNumber"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Email"
                headerTooltip="Email"
                tooltipField="email"
                field="email"
              ></AgGridColumn>
              <AgGridColumn
                initialPinned
                headerName="Clinic Name"
                field="clinicName"
                headerTooltip="Clinic Name"
                tooltipField="clinicName"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Medical Registration No."
                field="registrationNumber"
                headerTooltip="Medical Registration No."
                tooltipField="registrationNumber"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Banking Details"
                field="bankingDetails"
                cellRenderer="bankingDetails"
                onCellClicked={(e) => {
                  setOpen(e.data);
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Subscription Type"
                field="subscriptionType"
                headerTooltip="Subscription Type"
                tooltipField="subscriptionType"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Created At"
                field="createdAt"
                headerTooltip="Created At"
                tooltipValueGetter={(params) => {
                  if (params.value)
                    return new Date(params.value).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  return "-";
                }}
                valueFormatter={(params) => {
                  if (params.value)
                    return new Date(params.value).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  return "-";
                }}
              ></AgGridColumn>

              <AgGridColumn
                headerName="Actions"
                field="id"
                cellRenderer="actionCellRender"
                minWidth="350"
                width="350"
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </CardBody>
      </Card>
      <Modal
        show={open !== undefined}
        onHide={() => setOpen(undefined)}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="sm"
        centered
      >
        <Modal.Header closeButton title="Banking details">
          Banking Details
        </Modal.Header>

        <div className="w-full d-flex flex-column align-items-center justify-content-center py-4">
          <BankingDetails data={open} />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Table;
