import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import DoctorRoutes from "../components/Dashboard/DoctorRoutes";
import AuthRoutes from "../components/Authentication/AuthRoutes";
import OpenRoute from "../commons/OpenRoute";
import PrivateRoute from "../commons/PrivateRoute";
import DoctorTemplate from "../commons/DoctorTemplate";

import PageNotFound from "../commons/PageNotFound";

// Importing admin page
import Admin from "../components/Admin/Admin";

const Routers = () => {
  const routes = [...AuthRoutes, ...DoctorRoutes];
  const authRoutes = routes.filter((res) => res.auth);
  const normalRoutes = routes.filter((res) => !res.auth);
  const loggedIn = true;

  return (
    <Router>
      <Switch>
        {normalRoutes.map((route, i) => (
          <OpenRoute
            exact
            key={route.path}
            path={route.path}
            modules={route.modules}
            component={route.component}
          />
        ))}
        <DoctorTemplate>
          {authRoutes.map((route, i) => (
            <PrivateRoute
              exact
              key={route.path}
              path={route.path}
              modules={route.modules}
              component={route.component}
            />
          ))}
        </DoctorTemplate>
        <Route render={() => <PageNotFound />} />
      </Switch>
    </Router>
  );
};

export default Routers;
