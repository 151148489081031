import React from "react";
import Avatar, { ConfigProvider } from "react-avatar";

const PatientNameCellRenderer = (params) => {
  return (
    <div className="d-flex align-items-center w-100">
      <Avatar
        color="#c06c84"
        round
        size="30"
        name={params.data.name}
        src={params.data.logoLink}
        className="mr-2"
      />
      {params.data.name}{" "}
    </div>
  );
};

export default PatientNameCellRenderer;
