import React, { Suspense } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { Checkbox, Input } from "@mobiscroll/react5";
import Loader from "src/components/Dashboard/common/Loader";
import { approveClinicCreationRequest } from "./api";
import * as Yup from "yup";

const initialValues = {
  clinicNameVerified: false,
  nameVerified: false,
  registrationNumberVerified: false,
  bankDetailsVerified: false,
  freeUntil: 0,
  settlementPercentage: 0,
  razorpayAccountId: "",
};
const initialTouched = {
  clinicNameVerified: false,
  nameVerified: false,
  registrationNumberVerified: false,
  bankDetailsVerified: false,
  freeUntil: false,
  settlementPercentage: false,
  razorpayAccountId: false,
};

const validationSchema = Yup.object().shape({
  clinicNameVerified: Yup.boolean().test(
    "clinicNameVerified",
    "Must be checked",
    (value, ctx) => value
  ),
  nameVerified: Yup.boolean().test(
    "nameVerified",
    "Must be checked",
    (value, ctx) => value
  ),
  registrationNumberVerified: Yup.boolean().test(
    "registrationNumberVerified",
    "Must be checked",
    (value, ctx) => value
  ),
  bankDetailsVerified: Yup.boolean().test(
    "bankDetailsVerified",
    "Must be checked",
    (value, ctx) => value
  ),
  freeUntil: Yup.number().required("Required"),
  settlementPercentage: Yup.number().required("Required"),
  razorpayAccountId: Yup.string().required("Required"),
});

const ApprovalPopup = ({
  closeApprovalPopup,
  approvalPopup,
  params,
  value,
  setReload,
}) => {
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    handleReset,
    isValid,
  } = useFormik({
    initialValues,
    initialTouched,
    onSubmit: async (values) => {
      console.log(values);
      setLoader(true);

      let data = { requestId: params.value, ...values };
      if (value != "NA") {
        data.roles = value;
      }
      setLoader(true);
      const res = await approveClinicCreationRequest(data);
      if (res) {
        setReload(true);
      }
      setLoader(false);
      closeApprovalPopup();
    },
    validationSchema,
  });
  const [loader, setLoader] = React.useState(false);

  const handleCheckboxChange = (e) => {
    console.log(e);
    setFieldValue(e.target.name, e.target.checked, true);
  };

  return (
    <>
      <Loader show={loader} />
      <Modal
        show={approvalPopup}
        onHide={closeApprovalPopup}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}>
            <form action="" method="post" onSubmit={handleSubmit}>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <div className="custom-repeat-checkbox-cont">
                    <Checkbox
                      name="clinicNameVerified"
                      onChange={handleCheckboxChange}
                      checked={values?.clinicNameVerified}
                      error={true}
                      errorMessage={"ERror"}
                    >
                      Clinic Name Verified
                    </Checkbox>
                    <div className="mbsc-ios mbsc-error-message">
                      {touched?.clinicNameVerified && errors?.clinicNameVerified
                        ? errors?.clinicNameVerified
                        : null}
                    </div>
                  </div>
                </div>
                <div className="mbsc-col-12">
                  <div className="custom-repeat-checkbox-cont">
                    <Checkbox
                      name="nameVerified"
                      onChange={handleCheckboxChange}
                      checked={values?.nameVerified}
                    >
                      User Name Verified
                    </Checkbox>
                    <div className="mbsc-ios mbsc-error-message">
                      {touched?.nameVerified && errors?.nameVerified
                        ? errors?.nameVerified
                        : null}
                    </div>
                  </div>
                </div>
                <div className="mbsc-col-12">
                  <div className="custom-repeat-checkbox-cont">
                    <Checkbox
                      name="registrationNumberVerified"
                      onChange={handleCheckboxChange}
                      checked={values?.registrationNumberVerified}
                    >
                      Medical Registration Number Verified
                    </Checkbox>
                    <div className="mbsc-ios mbsc-error-message">
                      {touched?.registrationNumberVerified &&
                      errors?.registrationNumberVerified
                        ? errors?.registrationNumberVerified
                        : null}
                    </div>
                  </div>
                </div>
                <div className="mbsc-col-12">
                  <div className="custom-repeat-checkbox-cont">
                    <Checkbox
                      name="bankDetailsVerified"
                      onChange={handleCheckboxChange}
                      checked={values?.bankDetailsVerified}
                    >
                      Banking Details Verified
                    </Checkbox>
                    <div className="mbsc-ios mbsc-error-message">
                      {touched?.bankDetailsVerified &&
                      errors?.bankDetailsVerified
                        ? errors?.bankDetailsVerified
                        : null}
                    </div>
                  </div>
                </div>
                <div className="mbsc-col-12">
                  <Input
                    label="RazorPay Account ID*"
                    name="razorpayAccountId"
                    type="text"
                    inputStyle="underline"
                    labelStyle="floating"
                    placeholder=""
                    error={
                      touched?.razorpayAccountId && errors?.razorpayAccountId
                    }
                    errorMessage={errors?.razorpayAccountId}
                    value={values?.razorpayAccountId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="mbsc-col-12">
                  <Input
                    label="Settlement Percentage*"
                    name="settlementPercentage"
                    type="number"
                    step="0.01"
                    inputStyle="underline"
                    labelStyle="floating"
                    placeholder=""
                    error={
                      touched?.settlementPercentage &&
                      errors?.settlementPercentage
                    }
                    errorMessage={errors?.settlementPercentage}
                    value={values?.settlementPercentage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={0}
                  />
                </div>
                <div className="mbsc-col-12">
                  <Input
                    label="Subscription Free Until (in days)*"
                    name="freeUntil"
                    type="number"
                    step="1"
                    inputStyle="underline"
                    labelStyle="floating"
                    placeholder=""
                    error={touched?.freeUntil && errors?.freeUntil}
                    errorMessage={errors?.freeUntil}
                    value={values?.freeUntil}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={0}
                  />
                </div>

                <div className="mbsc-col-12 mt-3 d-flex flex-row justify-content-center">
                  <button
                    type="button"
                    onClick={closeApprovalPopup}
                    className="docg-patient-cancel-btn mr-2"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="docg-save-btn">
                    Approve
                  </button>
                </div>
              </div>
            </form>
          </Suspense>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApprovalPopup;
