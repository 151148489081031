import React, { Fragment, useState } from "react";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import Loader from "src/components/Dashboard/common/Loader";
import ActionCellRender from "./ActionCellRender";
import PatientNameCellRender from "./PatientNameCellRender";
import mobiscroll, { Switch } from "@mobiscroll/react";
import { Select } from "@mobiscroll/react5";
import clinicTemplateIcon from "../../assets/clinic-template-purple.svg";

import { listClinics, deactivateClinic, activateClinic } from "./api";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const Table = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [reload, setReload] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(["00"]);

  const listAllClinics = React.useCallback(
    async (displayRecords) => {
      setLoader(true);
      try {
        let _displayRecords = displayRecords;
        if (displayRecords <= 10) {
          _displayRecords = 10;
        }
        const res = await listClinics(+_displayRecords);
        setRowData(res.data);
        let value =
          res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
        setData((prevProps) => {
          let arr = [];
          for (let index = 0; index <= res.totalCount; index++) {
            let value = index < 10 ? `0${index}` : index;
            arr.push(value);
          }
          return arr;
        });
        setDisplayRecords(value);
        setTotalRecords(res.totalCount);
      } catch (e) {
        console.log(e);
      }
      setLoader(false);
    },
    [displayRecords, setRowData, listClinics]
  );

  React.useEffect(() => {
    if (reload) {
      listAllClinics(displayRecords);
      setReload(false);
    }
  }, [displayRecords, reload]);

  const ActivateSwitchClinic = React.useCallback(
    (params) => {
      const checked = params.value === "Active";
      const handleChange = async (e) => {
        setLoader(true);
        const data = {
          clinicId: params.data.id,
        };
        try {
          if (e.target.checked) {
            await activateClinic(data);
          } else {
            await deactivateClinic(data);
          }
          setReload(true);
        } catch (err) {
          console.log(err);
        }
        setLoader(false);
      };
      return (
        <>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Switch
              className="docg-table-switch"
              onChange={handleChange}
              checked={checked}
              theme="auto"
              position="start"
              color="success"
              name="noti2"
              id={`patient-${params.rowIndex}`}
            />
          </div>
        </>
      );
    },
    [activateClinic, deactivateClinic]
  );

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = async (e) => {
    setDisplayRecords((prev) => {
      if (prev != e.valueText) {
        setReload(true);
        return e.valueText;
      } else return prev;
    });
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 150,
    suppressMovable: true,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  return (
    <Fragment>
      <Loader show={loader} />
      <Card className="docg-card">
        <CardHeader className="customCardHeader d-flex justify-content-between">
          <CardTitle className="customCardTitle">
            <img
              style={{ maxWidth: "20px", maxHeight: "20px" }}
              src={clinicTemplateIcon}
              className="mr-2"
              alt=""
            />{" "}
            Clinics List
          </CardTitle>
        </CardHeader>
        <CardBody className="docg-card-body p-0">
          <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              <Select
                touchUi={false}
                onChange={onPageSizeChanged}
                value={displayRecords}
                data={data}
              />
            </label>
            <span>&nbsp;Records</span>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={rowData}
              defaultColDef={defaultColDef}
              frameworkComponents={{
                templateCellRender: ActionCellRender,
                patientNameCellRender: PatientNameCellRender,
                activateSwitchClinic: ActivateSwitchClinic,
              }}
              defaultColGroupDef={defaultColGroupDef}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={10}
              onGridReady={onGridReady}
              domLayout={"autoHeight"}
              animateRows={true}
              suppressAggFuncInHeader={true}
              suppressColumnVirtualisation={true}
            >
              <AgGridColumn
                headerName="Clinic Name"
                headerTooltip="Clinic Name"
                field="name"
                cellRenderer="patientNameCellRender"
                tooltipValueGetter={(params) => {
                  return params.data.name;
                }}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Created At"
                headerTooltip="Created At"
                field="createdAt"
                valueFormatter={(params) => {
                  if (params.value)
                    return new Date(params.value).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  return "-";
                }}
                tooltipValueGetter={(params) => {
                  if (params.value)
                    return new Date(params.value).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  return "-";
                }}
              ></AgGridColumn>

              <AgGridColumn
                headerName="Adress"
                headerTooltip="Adress"
                field="address"
                valueFormatter={(params) => (params.value ? params.value : "-")}
                tooltipField="address"
                minWidth={400}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Email"
                headerTooltip="Email"
                field="mail"
                tooltipField="mail"
                valueFormatter={(params) => (params.value ? params.value : "-")}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Mobile Number"
                headerTooltip="Mobile Number"
                tooltipField="helplineNumber"
                field="helplineNumber"
                valueFormatter={(params) => (params.value ? params.value : "-")}
              ></AgGridColumn>
              <AgGridColumn
                headerName="Actions"
                field="id"
                cellRenderer="templateCellRender"
                minWidth={500}
                // minWidth="fit-content"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Activate/Deactivate"
                field="status"
                cellRenderer="activateSwitchClinic"
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Table;
