import PropTypes from "prop-types";
import save from "src/assets/icons/save.svg";

const PrimarySubmitButton = ({
  title = "Save",
  classnames = "",
  isSubmitting,
}) => {
  return (
    <button
      type="submit"
      className={`docg-save-btn ${classnames}`}
      disabled={isSubmitting}
    >
      <img src={save} alt="" />
      {title}
    </button>
  );
};
PrimarySubmitButton.propTypes = {
  title: PropTypes.string,
  classnames: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
};
export default PrimarySubmitButton;
