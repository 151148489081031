import { Formik } from "formik";
import { PrimarySubmitButton } from "src/reusable/Buttons";
import Card from "src/reusable/Card";
import { Input } from "src/reusable/Form";
import * as Yup from "yup";

import { Switch } from "@mobiscroll/react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import FormikTextarea from "src/reusable/Form/input/Textarea";
import FormikSelect from "src/reusable/Form/select/Select";
import {
  createRazorpayPlan,
  deactivateAllRazorpayPlan,
  listActiveRazorpayPlans,
  listAllRazorpayPlans,
} from "./api";

const SubscriptionPlanSchema = Yup.object().shape({
  minPatientsAllowed: Yup.number().integer().required("*Required"),
  maxPatientsAllowed: Yup.number().integer().required("*Required"),
  amount: Yup.number().required("*Required"),
  companyPercentage: Yup.number().required("*Required"),
  unit: Yup.string().required("*Required"),
  interval: Yup.number().integer().required("*Required"),
  period: Yup.string().required("*Required"),
  description: Yup.string().required("*Required"),
  name: Yup.string().required("*Required"),
  subscriptionType: Yup.string().required("*Required"),
});

const SubscriptionPlanForm = ({ refetch }) => {
  const onSubmit = async (
    {
      name,
      subscriptionType,
      amount,
      interval,
      period,
      minPatientsAllowed,
      maxPatientsAllowed,
      unit,
      description,
      companyPercentage,
      applicableTaxVerbiage,
      verbiageForTransactFee,
    },
    { resetForm }
  ) => {
    const res = await createRazorpayPlan({
      subscriptionType,
      interval,
      period,
      minPatientsAllowed,
      maxPatientsAllowed,
      unit,
      companyPercentage,
      applicableTaxVerbiage,
      verbiageForTransactFee,
      item: {
        name,
        amount,
        description,
        currency: "INR",
      },
    });
    if (res) resetForm();
    refetch();
    refetchPlans();
    return res;
  };

  const { data, refetch: refetchPlans } = useQuery(
    ["list active subscription plans"],
    async function () {
      const res = await listActiveRazorpayPlans();
      return res;
    }
  );

  return (
    <Card title="Subscription Form">
      <Formik
        validationSchema={SubscriptionPlanSchema}
        initialValues={{
          period: "monthly",
          interval: 0,
          name: "",
          amount: 0,
          description: "",
          subscriptionType: null,
          minPatientsAllowed: 0,
          maxPatientsAllowed: 0,
          companyPercentage: 0,
          unit: "",
          applicableTaxVerbiage: "",
          verbiageForTransactFee: "",
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            <form className="pt-2 pb-3" onSubmit={handleSubmit}>
              <div className="mbsc-grid">
                <div className="mbsc-row">
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input label="Plan name" name="name" />
                  </div>

                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input
                      inputStyle="underline"
                      labelStyle="floating"
                      label="Subscription Type"
                      name="subscriptionType"
                      placeholder=""
                      type="text"
                      id="editInput"
                      list="suggestions"
                      cssClass="w-100"
                      autoComplete="off"
                    />
                    <div className="mbsc-col-12 d-flex align-items-end justify-content-center mt-2">
                      <datalist id="suggestions">
                        {data?.map(({ subscriptionType }) => (
                          <option
                            value={subscriptionType}
                            onClick={() => {
                              setFieldValue(
                                "subscriptionType",
                                subscriptionType,
                                true
                              );
                              // setShowSpecializations(false);
                            }}
                          >
                            {subscriptionType}
                          </option>
                        ))}
                      </datalist>
                    </div>
                  </div>

                  <div className="mbsc-col-6 mbsc-col-lg-4"></div>
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input
                      step="0.01"
                      label="Plan amount"
                      name="amount"
                      type="number"
                    />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input label="Frequency" name="interval" type="number" />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <FormikSelect
                      data={[
                        { text: "Daily", value: "daily" },
                        { text: "Weekly", value: "weekly" },
                        { text: "Monthly", value: "monthly" },
                        { text: "Yearly", value: "yearly" },
                      ]}
                      label="Period"
                      name="period"
                    />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input
                      label="Min. Patients allowed"
                      name="minPatientsAllowed"
                      type="number"
                    />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input
                      label="Max. Patients allowed"
                      name="maxPatientsAllowed"
                      type="number"
                    />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-4">
                    <Input label="Patients frequency unit" name="unit" />
                  </div>

                  <div className="mbsc-col-12">
                    <FormikTextarea label="Description" name="description" />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-3">
                    <Input
                      label="Company percent.(%)"
                      name="companyPercentage"
                    />
                  </div>
                  <div className="mbsc-col-3">
                    <Input
                      label="Tax Verbiage"
                      name="applicableTaxVerbiage"
                      placeholder="eg: applicable taxes"
                    />
                  </div>
                  <div className="mbsc-col-6 mbsc-col-lg-6 d-flex align-items-baseline">
                    <span className="mr-2">Online Transaction: </span>
                    <div className="flex-grow-1">
                      <Input
                        label="Transaction unit"
                        name="verbiageForTransactFee"
                        placeholder="eg: per transaction"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="mbsc-col-12 mt-2">
                    <i>
                      <small>
                        <b>NOTE: Frequency</b> is the number of time payment
                        required in a Period. Generally it is “1”.
                      </small>
                    </i>
                  </div>
                </div>
                <div className="mbsc-row mt-3">
                  <PrimarySubmitButton isSubmitting={isSubmitting} />
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </Card>
  );
};

const SubscriptionPlan = () => {
  const [displayRecords, setDisplayRecords] = useState("00");
  const [recordList, setData] = useState(["00"]);

  const ActivateSwitchClinic = React.useCallback((params) => {
    const checked = params.value;
    const handleChange = async (e) => {
      //  setLoader(true);

      try {
        await deactivateAllRazorpayPlan(params?.data?.subscriptionType);
        refetch();
      } catch (err) {
        console.log(err);
      }
      //  setLoader(false);
    };
    return (
      <>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Switch
            className="docg-table-switch"
            onChange={handleChange}
            checked={checked}
            theme="auto"
            position="start"
            color="success"
            name="noti2"
            id={`patient-${params.rowIndex}`}
          />
        </div>
      </>
    );
  }, []);

  const { data, refetch } = useQuery(
    ["list all subscription plans"],
    async function () {
      const res = await listAllRazorpayPlans();
      res.sort((itm) => itm.activePlan);
      return res;
    }
  );

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 150,
    suppressMovable: true,
  };

  return (
    <div className="mbsc-row">
      <div className="mbsc-col-12">
        <SubscriptionPlanForm refetch={refetch} />
      </div>
      <div className="mbsc-col-12 mt-2">
        <Card
          trailing={
            <>
              <button onClick={() => refetch()} className="docg-save-btn">
                Refresh
              </button>
            </>
          }
          title="Subscription List"
        >
          {/* <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              <Select
                touchUi={false}
                onChange={onPageSizeChanged}
                value={displayRecords}
                data={recordList}
              />
            </label>
            <span>&nbsp;Records</span>
          </div> */}
          <div
            className="ag-theme-alpine"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={data}
              defaultColDef={defaultColDef}
              frameworkComponents={{
                activateSwitchClinic: ActivateSwitchClinic,
              }}
              enableRangeSelection={true}
              pagination={true}
              paginationPageSize={10}
              domLayout={"autoHeight"}
              animateRows={true}
              suppressAggFuncInHeader={true}
              suppressColumnVirtualisation={true}
            >
              <AgGridColumn
                headerName="Plan name"
                field="item.name"
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Subscription type"
                field="subscriptionType"
                pinned="left"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Description"
                field="item.description"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Min Patients allowed"
                field="minPatientsAllowed"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Max Patients allowed"
                field="maxPatientsAllowed"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Patients unit"
                field="unit"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Amount"
                field="item.amount"
                valueFormatter={(params) =>
                  (params.value / 100).toLocaleString()
                }
              ></AgGridColumn>
              <AgGridColumn
                headerName="Interval"
                field="interval"
              ></AgGridColumn>
              <AgGridColumn headerName="Period" field="period"></AgGridColumn>
              <AgGridColumn
                headerName="Company Percentage"
                field="companyPercentage"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Applicable Tax Verbiage"
                field="applicableTaxVerbiage"
              ></AgGridColumn>
              <AgGridColumn
                headerName="Transaction unit"
                field="verbiageForTransactFee"
              ></AgGridColumn>

              <AgGridColumn
                headerName="Created At"
                field="createdAt"
                valueFormatter={(params) => {
                  if (params.value) {
                    let d = new Date(0);
                    d.setUTCSeconds(params.value);
                    return d.toLocaleString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hours: "2-digit",
                      minutes: "2-digit",
                    });
                  }
                  return "-";
                }}
              ></AgGridColumn>

              <AgGridColumn
                headerName="Activate/Deactivate"
                field="activePlan"
                cellRenderer="activateSwitchClinic"
              ></AgGridColumn>
            </AgGridReact>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
