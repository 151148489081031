import React, { useState, useEffect } from "react";
import "./Admin.css";
import Table from "./Table";

const Admin = (props) => {
  return (
    <div className="mbsc-col-12 docg-dashboard docg-vh-standard d-flex flex-column">
      <div className="mbsc-row">
        <div className="d-flex mbsc-col-12 pb-2 pt-2">
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Admin;
