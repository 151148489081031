import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useStore } from "src/store/Store";
import step4_doctor from "../../assets/Doctors 05.svg";
import line from "../../assets/Lines.svg";
import next_icon from "../../assets/Next icon.svg";
import resend_icon from "../../assets/Resend icon.svg";
import grid from "../../assets/grid.svg";
import signin_circle from "../../assets/signin-circle.svg";
import Loader from "src/components/Dashboard/common/Loader";
import { snackbar } from "@mobiscroll/react5";
import { confirmSignIn, fetchUserAttributes } from "@aws-amplify/auth";

const OtpVerification = ({ cognitoObject, onSuccess, mfaType }) => {
  const { globalState, dispatch } = useStore();
  const [otp, setOtp] = useState();
  const [formErrors, setFormErrors] = useState();
  const [loading, setLoading] = useState(false);

  console.log(globalState);

  const handleChange = (e) => {
    const value = e;
    setOtp(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (otp.length !== 6) {
        setFormErrors("Invalid OTP, OTP must be 6 digit");
      } else {
        setFormErrors();
        // const userAttributes = await fetchUserAttributes();
        let data = await confirmSignIn({
          challengeResponse: otp,
        });

        onSuccess(data);
      }
    } catch (err) {
      snackbar({
        message:
          err?.message ||
          "Some error occured while multi-factor authentication. Try again later.",
        color: "danger",
      });
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <>
      <Loader show={loading} />
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-5 doctor-images-left mbsc-col-12 p-0">
                <div className="left-signup-div" id="reset-password-page"></div>
                <img
                  alt=""
                  src={step4_doctor}
                  className="left-signup-img left-steps-img"
                />
              </div>
              <div className="mbsc-col-lg-6 mbsc-col-md-6 mbsc-col-12 signup-right">
                <img alt="" src={grid} className="grid-top" />
                <img alt="" src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    {/* <p className="signup-link">Step 4/5</p> */}
                    {mfaType === "SMS" ? (
                      <p className="get-txt mt-4">OTP Verification</p>
                    ) : (
                      <p className="get-txt mt-4">
                        Enter TOTP from your authenticator app
                      </p>
                    )}
                    {/* <p className="signup-link mt-2">
                      We'll text you on XXXXXX4321
                    </p> */}
                    <div className="signup-wrapper-inner">
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        hasErrored={formErrors}
                        errorStyle={"otp-input-error"}
                        separator={<span>&emsp;</span>}
                        containerStyle={"otp-container"}
                        inputStyle={"form-input-otp"}
                        isInputNum={true}
                      />
                      <br />
                      {formErrors ? (
                        <span className="otp-error">{formErrors}</span>
                      ) : (
                        ""
                      )}

                      <br />
                      <button onClick={handleSubmit} className="purple-button">
                        <img alt="" src={next_icon} />
                        &nbsp;&nbsp; Continue
                      </button>
                      <br />
                      <br />
                      {/* <button className="purple-color">
                        <img alt="" src={resend_icon} className="resend-icon" />
                        Send me a new code
                      </button> */}
                    </div>
                  </div>
                </div>
                <img alt="" src={line} className="lines-bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
